import React from 'react';
import { StyledSVG } from '../Icon';

export const ClockIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill={color}
      {...props}
    >
      <path
        style={{ fill: color }}
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10c5.5,0,10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8
	s8,3.6,8,8S16.4,20,12,20z M12.5,7H11v6l5.2,3.1l0.8-1.2l-4.5-2.7V7z"
      />
    </StyledSVG>
  );
};
