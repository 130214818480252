import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twjoin } from '../utils';
import { paths } from '../../../utils/routeUtils';
import { useAuthModes } from '../../../hooks';

interface TabsProps {
  currentRoute: string;
}

interface TabItemProps {
  active: boolean;
  cypressHint: string | null;
  onClick: () => void;
  text: string;
}

const tabConfig = {
  socialSignIn: {
    path: paths.signup(),
  },
  emailPassword: {
    path: paths.v3.login.email(),
  },
  sso: {
    path: paths.v3.login.sso(),
  },
  registerNewUser: {
    path: paths.v3.register.newUserCloud(),
  },
} as const;

const TabItem = ({
  text,
  active,
  cypressHint,
  onClick,
}: TabItemProps): JSX.Element => {
  return (
    <span
      onClick={onClick}
      className={twjoin(
        'text-center px-3 py-1 font-inter rounded-[48px] text-sm font-medium mx-[2px]',
        !active
          ? 'hover:bg-slate-200 hover:cursor-pointer'
          : 'text-white bg-v3-tab-active'
      )}
      data-cy={cypressHint}
    >
      {text}
    </span>
  );
};

const Tabs = (props: TabsProps): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const {
    isSocialSignInSupported,
    isEmailSignInSupported,
    isSamlAuthSupported,
  } = useAuthModes();

  const onClick = (path: string) => () => {
    if (props.currentRoute === path) {
      return;
    }
    navigate({
      pathname: path,
      search,
    });
  };

  const onClickPathEmail = React.useMemo(() => {
    if (props.currentRoute === tabConfig.registerNewUser.path) {
      return tabConfig.registerNewUser.path;
    }

    return tabConfig.emailPassword.path;
  }, [props.currentRoute]);

  return (
    <div className="flex flex-row h-11 border border-[#D2D6DB] rounded-3xl items-center p-2 justify-between mb-5 w-max self-center">
      {isSocialSignInSupported ? (
        <TabItem
          text="Social Sign In"
          data-testid="social-sign-in-tab"
          active={tabConfig.socialSignIn.path === props.currentRoute}
          cypressHint="social-sign-in"
          onClick={onClick(tabConfig.socialSignIn.path)}
        />
      ) : null}
      {isEmailSignInSupported ? (
        <TabItem
          text="Email & Password"
          data-testid="email-and-password-tab"
          active={
            props.currentRoute === tabConfig.emailPassword.path ||
            props.currentRoute === tabConfig.registerNewUser.path
          }
          cypressHint="email-and-password"
          onClick={onClick(onClickPathEmail)}
        />
      ) : null}

      {isSamlAuthSupported ? (
        <TabItem
          text="SSO"
          cypressHint="sso"
          data-testid="sso-tab"
          onClick={onClick(tabConfig.sso.path)}
          active={props.currentRoute === tabConfig.sso.path}
        />
      ) : null}
    </div>
  );
};

export default Tabs;
