import React from 'react';

interface DropDownCustomProps {
  SelectedComponent: React.ElementType;
  ItemComponent: React.ElementType;
  onChange: (value: any) => void;
  items: any[];
  defaultValue?: any;
}

export default function DropDownCustom({
  SelectedComponent,
  ItemComponent,
  onChange,
  items,
  defaultValue,
}: DropDownCustomProps) {
  const [selected, setSelected] = React.useState(defaultValue);
  const [open, setOpen] = React.useState(false);

  return (
    <button
      className="relative cursor-pointer"
      onClick={() => setOpen(!open)}
      onBlur={() => setOpen(false)}
    >
      <SelectedComponent {...selected} />
      {open && (
        <div className="absolute bg-white rounded-sm shadow-lg z-10 -left-6 mt-1">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex rounded-sm items-center justify-between py-2 px-4 cursor-pointer hover:bg-gray-200"
              onClick={() => {
                setSelected(item);
                onChange(item);
                setOpen(false);
              }}
            >
              <ItemComponent {...item} />
            </div>
          ))}
        </div>
      )}
    </button>
  );
}
