/* eslint-disable no-underscore-dangle */

export const PROD_DOMAIN = 'hasura.io';
export const PROD_SUBDOMAIN = 'cloud';

export const SENTRY_DSN =
  'https://78cecf1913df4d838c97f68459686bd3@o417608.ingest.sentry.io/5318352';

const scheme = proto => {
  return window.location.protocol === 'https:' ? `${proto}s` : proto;
};

const NO_VALUE = '';

const getDefaultEnv = (key, envName, def) => {
  if (
    window.__env[key] === `{{env "${envName}"}}` ||
    window.__env[key] === NO_VALUE
  ) {
    if (window.location.hostname === 'localhost') {
      return process.env[`REACT_APP_${envName}`] || def;
    }
    return def;
  }
  return window.__env[key];
};

const ROOT_DOMAIN = getDefaultEnv(
  'rootDomain',
  'ROOT_DOMAIN',
  'lux-dev.hasura.me'
);
export const IS_TESTING = process.env.NODE_ENV === 'test';
export const IS_PRODUCTION =
  !!ROOT_DOMAIN &&
  ROOT_DOMAIN.includes(PROD_DOMAIN) &&
  process.env.NODE_ENV === 'production';

// Auth host selection logic -
//  Prod - from HTML template
//  local build - from HTML template
//  local dev - from react app's .env if available or from HTML template
// similar logic for data host
const AUTH_HOST = getDefaultEnv(
  'authHost',
  'AUTH_HOST',
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_AUTH_HOST || `auth.${ROOT_DOMAIN}`
    : `auth.${ROOT_DOMAIN}`
);
const ACCOUNTS_HOST = getDefaultEnv(
  'accountsDomain',
  'ACCOUNTS_DOMAIN',
  `accounts.${ROOT_DOMAIN}`
);
const DATA_HOST = getDefaultEnv(
  'dataHost',
  'DATA_HOST',
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_DATA_HOST || `data.${ROOT_DOMAIN}`
    : `data.${ROOT_DOMAIN}`
);

export const PROMETHEUS_EXPORTER_URL = getDefaultEnv(
  'prometheusExporterUrl',
  'PROMETHEUS_EXPORTER_URL',
  `${scheme('http')}://prometheus-exporter.${ROOT_DOMAIN}/metrics`
);

const VERCEL_INTEGRATION_BASE_URL = `https://vercel.com/integrations/`;

const VERCEL_INTEGRATION_SLUG = getDefaultEnv(
  'verceIntegrationSlug',
  'VERCEL_INTEGRATION_SLUG',
  `hasura`
);

export const VERCEL_INTEGRATION_URL =
  VERCEL_INTEGRATION_BASE_URL + VERCEL_INTEGRATION_SLUG;

export const VERCEL_NEW_PROJECT_LIMIT = getDefaultEnv(
  'vercelNewProjectLimit',
  'VERCEL_NEW_PROJECT_LIMIT',
  2
);

export const DEFAULT_VPC_LIMIT = Number(
  getDefaultEnv(
    'defaultVPCLimit',
    'HASURA_CLOUD_DEDICATED_VPC_DEFAULT_LIMIT',
    '1'
  )
);

export const AUTH_BASE = `${scheme('http')}://${AUTH_HOST}`;
export const ACCOUNT_BASE = `${scheme('http')}://${ACCOUNTS_HOST}`;

export const authEndpoints = {
  login: `${AUTH_BASE}/login`,
  logout: `${AUTH_BASE}/logout`,
  signup: `${AUTH_BASE}/register`,
  userInfo: `${AUTH_BASE}/webhook/api`,
  uaLogin: `${AUTH_BASE}/login-user-assumption`,
  uaLogout: `${AUTH_BASE}/logout-user-assumption`,
  confirm: `${AUTH_BASE}/confirm`,
  recover: `${AUTH_BASE}/recover`,
  resetPassword: `${AUTH_BASE}/recover/end`,
  confirmEmail: `${AUTH_BASE}/confirmation_mail`,
  acceptLogin: challenge =>
    `${AUTH_BASE}/hydra/login/verify?login_challenge=${challenge}`,
  githubOAuthUrl: `${AUTH_BASE}/oauth2/github`,
  googleOAuthUrl: `${AUTH_BASE}/oauth2/google`,
  consentOAuthUrl: `${AUTH_BASE}/oauth2/tnc-consent`,
  fetchConsentInfo: challenge =>
    `${AUTH_BASE}/hydra/consent?consent_challenge=${challenge}`,
  verifyConsent: challenge =>
    `${AUTH_BASE}/hydra/consent/accept?consent_challenge=${challenge}`,
  rejectConsent: challenge =>
    `${AUTH_BASE}/hydra/consent/reject?consent_challenge=${challenge}`,
  samlSSO: `${AUTH_BASE}/saml/auth`,
};

export const WS_URL = `${scheme('ws')}://${DATA_HOST}/v1/graphql`;
export const GRAPHQL_URL = `${scheme('http')}://${DATA_HOST}/v1/graphql`;

export const HASURA_DOCS_2_BASE_URL = `https://hasura.io/docs/2.0`;

export const HEROKU_OAUTH_CLIENT_ID = getDefaultEnv(
  'herokuOAuthClientId',
  'HEROKU_OAUTH_CLIENT_ID',
  process.env.REACT_APP_HEROKU_OAUTH_CLIENT_ID
);

export const GITHUB_INTEGRATION_OAUTH_CLIENT_ID = getDefaultEnv(
  'githubIntegrationOauthClientId',
  'GITHUB_INTEGRATION_OAUTH_CLIENT_ID',
  process.env.REACT_APP_GITHUB_INTEGRATION_OAUTH_CLIENT_ID
);

export const STRIPE_KEY = getDefaultEnv(
  'stripeClientKey',
  'STRIPE_KEY',
  process.env.REACT_APP_STRIPE_KEY
);

export const FREE_TIER_DEFAULT_REGION_V1 = getDefaultEnv(
  'freeTierDefaultRegionV1',
  'FREE_TIER_DEFAULT_REGION_V1',
  'us-east-2'
);
export const FREE_TIER_DEFAULT_REGION_V2 = getDefaultEnv(
  'freeTierDefaultRegionV2',
  'FREE_TIER_DEFAULT_REGION_V2',
  'us-east-2'
);

export const getFreeTierRegion = project => {
  if (!project || !project.tenant) {
    return FREE_TIER_DEFAULT_REGION_V2;
  }
  return project.tenant?.major_version === 2
    ? FREE_TIER_DEFAULT_REGION_V2
    : FREE_TIER_DEFAULT_REGION_V1;
};

export const GCP_FREE_TIER_REGION = getDefaultEnv(
  'gcpFreeTierRegion',
  'GCP_FREE_TIER_REGION',
  'asia-south2'
);

export const POSTHOG_TOKEN = getDefaultEnv(
  'posthogToken',
  'POSTHOG_TOKEN',
  process.env.REACT_APP_POSTHOG_TOKEN
);

export const AUTH_METHODS = getDefaultEnv(
  'authMethods',
  'AUTH_METHODS',
  'password,google,github'
);

const isEEMode = () => {
  const eeMode = getDefaultEnv(
    'eeMode',
    'EE_MODE',
    process.env.REACT_APP_EE_MODE
  );
  return eeMode === 'true' || eeMode === true;
};

// Acceptable values: 'STANDARD' , 'LITE'
const EE_DEPLOY_TYPE = getDefaultEnv(
  'eeDeployType',
  'EE_DEPLOY_TYPE',
  'STANDARD'
);

const isEELite = () => {
  return isEEMode() && EE_DEPLOY_TYPE === 'LITE';
};

export const GITHUB_INTEGRATION_APP_NAME = getDefaultEnv(
  'githubIntegrationAppName',
  'GITHUB_INTEGRATION_APP_NAME',
  process.env.REACT_APP_GITHUB_INTEGRATION_APP_NAME
);

const getDomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  return parts.slice(-2).join('.');
};

export const EE_MODE = isEEMode();

export const EE_LITE_MODE = isEELite();

export const APP_NAME = EE_MODE ? 'Hasura EE' : 'Hasura Cloud';

export const HASURA_BRANDING = EE_MODE ? 'hasura-enterprise' : 'hasura-cloud';

export const PRICING_PAGE_URL = `https://hasura.io/pricing/v2`;

export const LEGACY_PRICING_PAGE_URL = `https://hasura.io/pricing/legacy/`;

export const DATADOG_DOCS_LINK = `${HASURA_DOCS_2_BASE_URL}/observability/cloud/datadog/`;

export const NEW_RELIC_DOCS_LINK = `${HASURA_DOCS_2_BASE_URL}/observability/cloud/newrelic/`;

export const AZURE_MONITOR_DOCS_LINK = `${HASURA_DOCS_2_BASE_URL}/observability/cloud/azure-monitor/`;

export const PROMETHEUS_DOCS_LINK = `${HASURA_DOCS_2_BASE_URL}/observability/cloud/prometheus/`;

export const PROJECT_UPDATE_DOCS_LINK = `${HASURA_DOCS_2_BASE_URL}/hasura-cloud/projects/move-project-v2-manual/`;

export const GITHUB_INTEGRATION_INSTALLATION_URL = `https://github.com/apps/${GITHUB_INTEGRATION_APP_NAME}/installations/new`;

export const GITHUB_INTEGRATION_STARTER_KIT = `https://github.com/hasura/github-integration-starter`;

export const GITHUB_TROUBLESHOOT_GUIDE = `${HASURA_DOCS_2_BASE_URL}/cloud-ci-cd/github-integration/#github-integration-troubleshooting`;

// Azure Integration Read more links ***************** //

export const AZURE_WORKSPACE_ID_DOC_REF = `https://docs.microsoft.com/en-us/azure/azure-monitor/logs/data-collector-api#sample-requests`;

export const AZURE_SHARED_KEY_DOC_REF = `https://docs.microsoft.com/en-us/azure/azure-monitor/logs/data-collector-api#sample-requests`;

export const AZURE_LOG_TYPE_DOC_REF = `https://docs.microsoft.com/en-us/azure/azure-monitor/logs/data-collector-api#request-headers`;

export const AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = `https://docs.microsoft.com/en-us/azure/bot-service/bot-service-resources-app-insights-keys?view=azure-bot-service-4.0#instrumentation-key`;

export const AZURE_ACTIVE_DIRECTORY_DOC_REF = `
  https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in
`;

export const AZURE_ACTIVE_DIR_TENANT_ID_DOC_REF = `https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in`;

export const AZURE_ACTIVE_DIR_CLIENT_ID_DOC_REF = `https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#get-tenant-and-app-id-values-for-signing-in`;

export const AZURE_ACTIVE_DIR_CLIENT_SECRET_DOC_REF = `
https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal#option-2-create-a-new-application-secret
`;

export const AZURE_REGION_DOC_REF = `https://docs.microsoft.com/en-us/azure/azure-monitor/essentials/metrics-custom-overview#region`;

export const AZURE_NAMESPACE_DOC_REF = `
https://docs.microsoft.com/en-us/azure/azure-monitor/essentials/metrics-custom-overview#namespace
`;

export const HEROKU_INTEGRATION_SERVICE = 'ADD_HEROKU_INTEGRATION';

export const NEWRELIC_INTEGRATION_SERVICE = 'ADD_NEWRELIC_INTEGRATION';

export const DATADOG_INTEGRATION_SERVICE = 'ADD_DATADOG_INTEGRATION';

export const AZURE_INTEGRATION_SERVICE = 'ADD_AZURE_INTEGRATION';

export const OPENTELEMETRY_INTEGRATION_SERVICE =
  'ADD_OPENTELEMETRY_INTEGRATION';

export const PROMETHEUS_INTEGRATION_SERVICE = 'ADD_PROMETHEUS_INTEGRATION';

export const PROMETHEUS_NAMESPACE_DOC_REF = `https://prometheus.io/docs/practices/naming/#metric-names`;

export const PROMETHEUS_LABELS_DOC_REF = `https://prometheus.io/docs/practices/naming/#labels`;

export const PROMETHEUS_ACCESS_TOKEN_DOC_REF = `${HASURA_DOCS_2_BASE_URL}/observability/cloud/prometheus/#access-token`;

export const PROMETHEUS_CONNECTION_URL_DOC_REF = `${HASURA_DOCS_2_BASE_URL}/observability/cloud/prometheus/#connection-url`;

export const GITHUB_FEATURE_DISCUSSION_ISSUE = `https://github.com/hasura/graphql-engine/discussions/7322`;

export const HEROKU_DOCS_LINK = `${HASURA_DOCS_2_BASE_URL}/hasura-cloud/heroku-url-sync/`;

export const HASURA_TERMS_AND_CONDITIONS_LINK = `https://hasura.io/legal/hasura-cloud-terms-of-service/`;

export const HASURA_PRIVACY_POLICY_LINK = `https://hasura.io/legal/hasura-privacy-policy/`;

export const ADD_COLLABORATOR = 'ADD_COLLABORATOR';

export const MODIFY_COLLABORATOR = 'MODIFY_COLLABORATOR';

export const ADD_BILLING_OWNER = 'ADD_BILLING_OWNER';

export const PUBLIC_GRAPHIQL_URL = `${window.location.origin}/public/graphiql`;

export const PROJECT_METRICS_URL = `https://grafana.metrics.pro.${getDomain()}/d/be0u893keptkwd/project-graphql-metrics`;

export const CONFIG_MOVED_ON_MESSAGE = `Environment variables have changed since you last viewed them. Please review and retry updating.`;

export const PROJECTS_QUERY_LIMIT = 10;

export const SELF_AGENT_NAME = 'CloudDashboard';

export const EE_LITE_DEFAULT_ADMIN_USER = 'admin';

export const NEON_OAUTH_CLIENT_ID = getDefaultEnv(
  'neonOAuthClientId',
  'NEON_OAUTH_CLIENT_ID',
  process.env.REACT_APP_NEON_OAUTH_CLIENT_ID
);

export const LAUNCHDARKLY_CLIENT_ID = getDefaultEnv(
  'launchDarklyClientId',
  'LAUNCHDARKLY_CLIENT_ID',
  process.env.LAUNCHDARKLY_CLIENT_ID
);

export const PROFESSIONAL_PLAN_BASE_PRICE = '1.50';

export const USER_ASSUMPTION_ROLE = 'user_read';

export const HASURA_CLOUD_STATUS_PAGE = 'https://status.hasura.io';

export const HASURA_CLOUD_STATUS_ENDPOINT = `${HASURA_CLOUD_STATUS_PAGE}/api/v2/status.json`;

export const TWO_MINUTES_MS = 1000 * 60 * 2;

export const SLACK_CLIENT_ID = getDefaultEnv(
  'slackClientID',
  'SCHEMA_REGISTRY_SLACK_CLIENT_ID',
  process.env.REACT_APP_SLACK_CLIENT_ID
);

export const SLACK_ROOT_DOMAIN = getDefaultEnv(
  'slackRootDomain',
  'SLACK_ROOT_DOMAIN',
  process.env.REACT_APP_SLACK_ROOT_DOMAIN
);

export const AZURE_APP_ID = getDefaultEnv(
  'azureAppId',
  'AZURE_APP_ID',
  process.env.AZURE_APP_ID
);

export const REO_CLIENT_ID = getDefaultEnv(
  'reoClientId',
  'REO_CLIENT_ID',
  process.env.REO_CLIENT_ID
);

export const V3_CONSOLE_URL = getDefaultEnv(
  'v3ConsoleUrl',
  'V3_CONSOLE_URL',
  process.env.V3_CONSOLE_URL
);
