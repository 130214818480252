import React, { SVGProps } from 'react';

const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 90 90"
    fill={props?.fill ?? '#fff'}
    {...props}
  >
    <path d="M27.7 9.7c-1.7 1.6.1 4.4 12.4 19.4L53.2 45 40 61c-7.9 9.7-13 16.7-12.8 17.8.2 1.3 1.5 1.8 5.3 2l5 .2 14.3-17.4C59.6 54 66 45.6 66 45s-6.4-8.9-14.2-18.5L37.5 9h-4.6c-2.5 0-4.9.3-5.2.7z" />
  </svg>
);
export default SVGComponent;
