import React from 'react';

const useOnKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  const setKeyPressedState = React.useCallback(
    (newState: boolean) => ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        setKeyPressed(newState);
      }
    },
    [targetKey]
  );

  const downHandler = setKeyPressedState(true);

  const upHandler = setKeyPressedState(false);

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
};

const useOnKeyPressEnter = () => useOnKeyPress('Enter');

export default useOnKeyPressEnter;
