import React from 'react';
import { HasuraDDNLaunchBanner } from './HasuraDDNLaunchBanner';
import HasuraConBanner from './HasuraConBanner';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from '../../utils/types';

/**
 *
 * In the future, we should extend this and use a feature flag based toggling of
 * the active banners.
 */
export const useActiveBanner = () => {
  const ldClient = useLDClient();
  const activeBanner = ldClient?.variation(FeatureFlags.ActiveBanner);

  return activeBanner ?? '';
};

export const Banner = () => {
  const activeBanner = useActiveBanner();

  if (activeBanner === 'ddn-launch') return <HasuraDDNLaunchBanner />;

  if (activeBanner === 'hasura-con-24') return <HasuraConBanner />;

  /**
   * Display no banner if there is no active one.
   */
  return null;
};
