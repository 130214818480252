import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Text, Button } from '../UIKit';
import { paths } from '../../utils/routeUtils';

interface CreateEmailAccountProps {
  showSignupForm: () => void;
  fontSize?: string;
}

export const CreateEmailAccount: React.FC<CreateEmailAccountProps> = ({
  showSignupForm,
  fontSize = '0.875rem',
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleCreateEmailAccount = () => {
    showSignupForm();
    navigate({
      pathname: paths.signup(),
      search: location.search,
    });
  };

  return (
    <Button
      variant="div"
      id="create-account-btn"
      onClick={handleCreateEmailAccount}
    >
      <Text color="red.primary" fontSize={fontSize} hover="underline" ml="-6px">
        Sign up with Email
      </Text>
    </Button>
  );
};
