import React, { useReducer, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Flex,
  Text,
  Heading,
  Button,
  Icon,
  Label,
  Input,
  Link,
} from '../UIKit';
import loginReducer, {
  CHANGE_INPUT,
  LOGGING_IN,
  LOGIN_FAIL,
  defaultState,
  fieldNames,
} from './LoginActions';
import { authEndpoints } from '../../utils/constants';
import { paths } from '../../utils/routeUtils';
import { getSearchParam } from '../../utils/helpers';
import { AuthAlert } from './AuthAlert';
import { LoginState, LoginAction } from './LoginActions';
import {
  getChangedEmailFromSessionStrg,
  clearChangedEmailInSessionStrg,
} from '../../utils/sessionStorage';
import NetworkLoader from '../../utils/NetworkLoader';

const loginUrl = authEndpoints.login;

type LoginFormProps = {
  loginCallback: (loginRedirectState: any) => void;
  redir: string;
  handleResendEmail: (email: string, handleFn: (err: string) => void) => void;
  toggleForgotPasswordForm: (toggle: boolean) => void;
  toggleSignupFormStatus: (toggle: boolean) => void;
  changeLoginEmail: (email: string) => void;
  signupURL?: string;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  loginCallback,
  redir,
  handleResendEmail,
  toggleForgotPasswordForm,
  toggleSignupFormStatus,
  changeLoginEmail,
  signupURL = paths.signup(),
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loginState, dispatch] = useReducer<
    (state: LoginState, action: LoginAction) => LoginState
  >(loginReducer, defaultState);
  const [postConfirmation, setPostConfirmation] = useState(false);
  const [postEmailChange, setPostEmailChange] = useState(false);

  const { isLoggingIn, loginErr, loginForm } = loginState;
  const { email, password } = loginForm;
  const isConfirmationError =
    loginErr &&
    loginErr.message &&
    loginErr.message.includes('not been confirmed');

  useEffect(() => {
    let confirmationTimer: ReturnType<typeof setTimeout>;
    if (getSearchParam(location.search, 'is_confirmed') === 'true') {
      setPostConfirmation(true);
      confirmationTimer = setTimeout(() => {
        setPostConfirmation(false);
      }, 5000);
    } else {
      const changedEmail = getChangedEmailFromSessionStrg();
      clearChangedEmailInSessionStrg();
      if (changedEmail) {
        setPostEmailChange(true);
        dispatch({
          type: CHANGE_INPUT,
          data: {
            key: fieldNames.email,
            value: changedEmail,
          },
        });
        confirmationTimer = setTimeout(() => {
          setPostEmailChange(false);
        }, 5000);
      }
    }
    return () => {
      if (confirmationTimer) {
        clearTimeout(confirmationTimer);
      }
    };
  }, []);

  const handleForgotPassword = () => {
    toggleForgotPasswordForm(true);
  };

  const setLoginError = (err: string) => {
    dispatch({
      type: LOGIN_FAIL,
      data: err,
    });
  };

  const updateInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.getAttribute('data-field-name');
    if (fieldName) {
      if (fieldName === fieldNames.email) {
        // Save login email to avoid re-typing in forgot password window
        changeLoginEmail(e.target.value);
      }
      dispatch({
        type: CHANGE_INPUT,
        data: {
          key: fieldName,
          value: e.target.value,
        },
      });
      dispatch({
        type: LOGIN_FAIL,
        data: '',
      });
    }
  };

  const handleLogin = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch({
      type: LOGGING_IN,
    });

    const loginBody = {
      email: loginForm.email.toLowerCase(),
      password: loginForm.password,
    };

    const options: RequestInit = {
      credentials: 'include',
      body: JSON.stringify(loginBody),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    };

    return fetch(`${loginUrl}?redir=${redir}`, options)
      .then(resp => {
        return resp.json();
      })
      .then(resp => {
        if ('status' in resp && resp.status === 'failure') {
          if ('error' in resp) {
            throw new Error(resp.error);
          }
          if ('message' in resp) {
            throw new Error(resp.message);
          }
          throw new Error(
            'Unexpected error. Get in touch with us for more info'
          );
        }
        loginCallback(resp.location);
      })
      .catch(err => {
        if (err && err.message && err.message.includes('Failed to fetch')) {
          dispatch({
            type: LOGIN_FAIL,
            data: 'Sign in failed. Please check your internet connection',
          });
        } else {
          dispatch({
            type: LOGIN_FAIL,
            data: err,
          });
        }
      });
  };

  const loginButtonText = isLoggingIn ? 'Signing in' : 'Sign in to Hasura';

  const getEmailVerificationMessage = () => {
    if (postConfirmation) {
      return (
        <Flex pb="10px">
          <AuthAlert successMessage="Your account has been verified. Please Sign in." />
        </Flex>
      );
    }

    if (postEmailChange) {
      return (
        <Flex pb="10px">
          <AuthAlert successMessage="Your email has been changed. Please Sign in." />
        </Flex>
      );
    }

    return null;
  };

  const handleSignupButton = () => {
    toggleSignupFormStatus(false);
    navigate({
      pathname: signupURL,
      search: location.search,
    });
  };

  return (
    <>
      <Flex mb="20px" justifyContent="space-between" minHeight="45px">
        {isLoggingIn && <NetworkLoader />}
        <Heading fontSize="24px" fontFamily="Poppins">
          Sign In
        </Heading>
        <Flex width="auto">
          <Text fontSize="0.875rem">New?&nbsp;</Text>
          <Button
            variant="div"
            id="create-account-btn"
            onClick={handleSignupButton}
          >
            <Text color="red.primary" fontSize="0.875rem" hover="underline">
              Sign Up
            </Text>
          </Button>
        </Flex>
      </Flex>
      <Flex flexDirection="column" as="form" onSubmit={handleLogin}>
        <Label>
          <Icon type="email" mr="12px" isActive={email || ''} />
          <Input
            type="email"
            id="sign-in-email-input"
            data-field-name={fieldNames.email}
            placeholder="Email"
            value={loginForm.email}
            onChange={updateInputValue}
          />
        </Label>
        <Label justifyContent="space-between">
          <Icon type="password" mr="12px" isActive={password || ''} />
          <Input
            type="password"
            id="sign-in-password-input"
            data-field-name={fieldNames.password}
            placeholder="Password"
            value={loginForm.password}
            onChange={updateInputValue}
          />
          <Text
            id="forgot-password-btn"
            role="button"
            color="red.primary"
            fontSize="14px"
            fontFamily="Poppins"
            pointer
            hover="underline"
            onClick={handleForgotPassword}
          >
            Forgot?
          </Text>
        </Label>
        <Button
          type="submit"
          variant="primary"
          size="large"
          width="100%"
          mt="8px"
          mb="20px"
          fontFamily="Poppins"
          isLoading={isLoggingIn}
          disabled={isLoggingIn}
          id="login-btn"
        >
          {loginButtonText}
        </Button>
        {/* email verification alert(success) */}
        {getEmailVerificationMessage()}
        {/* Auth error alert */}
        {loginErr ? (
          <>
            <AuthAlert errorMessage={loginErr} />
            {isConfirmationError && (
              <Flex width="100%" justifyContent="flex-start" mb="30px">
                <Text fontSize="0.875rem" pr="5px" noWrap>
                  Not received the verification email?&nbsp;
                </Text>
                <Link
                  href="#"
                  color="blue.link"
                  hover="underline"
                  onClick={() => handleResendEmail(email, setLoginError)}
                >
                  <Flex>
                    <Icon type="resend" color="#337ab7" />
                    &nbsp; Resend
                  </Flex>
                </Link>
              </Flex>
            )}
          </>
        ) : (
          <Text as="span" height="16px" />
        )}
      </Flex>
    </>
  );
};
