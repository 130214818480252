import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { StyledPopover } from './Popover';
import { DashboardContext } from '../../../Dashboard/store';

const popoverGenerator = content => {
  return (
    <Popover show>
      <Popover.Content>{content}</Popover.Content>
    </Popover>
  );
};

export const PopOver = props => {
  const { content, placement, children } = props;
  const target = React.useRef(null);

  const { isSidebarModalActive } = React.useContext(DashboardContext);

  // Hiding in the case of sidebar drawer is active.
  if (isSidebarModalActive) {
    return null;
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      overlay={popoverGenerator(content)}
    >
      <StyledPopover ref={target} aria-hidden="true" {...props}>
        {children}
      </StyledPopover>
    </OverlayTrigger>
  );
};

PopOver.defaultProps = {
  placement: 'right',
};
