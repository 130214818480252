import styled from 'styled-components';
import {
  color,
  border,
  typography,
  layout,
  space,
  shadow,
} from 'styled-system';

export const StyledCheckBox = styled.div`
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    width: 24px;
    height: 24px;

    & + label {
      position: relative;
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    }

    & + label:before {
      content: '';
      display: inline-block;
      vertical-align: text-top;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background: ${({ background }) => background || 'transparent'};
      border: 1px solid #E4E4E4;
      margin-right: 8px;
      margin-top: -2px;
    }

    &:hover + label:before {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    }

    label. &:disabled + label {
      color: green;
      cursor: auto;
    }

    box. &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 11px;
      background: #2EB67D;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 #2EB67D, 4px 0 0 #2EB67D, 4px -2px 0 #2EB67D,
        4px -4px 0 #2EB67D, 4px -6px 0 #2EB67D, 4px -8px 0 #2EB67D;
      transform: rotate(45deg);
    }
  }

  input[type="checkbox"]:focus + label::before {
    box-shadow: 0 0 0 3px #1EB4D4;
  }

  ${color}
  ${border}
  ${typography}
  ${layout}
  ${space}
  ${shadow}
`;
