/**
 * UserContext is a React Context that provides the current user's information
 * to all components that are wrapped by the UserContextProvider.
 */

/**
 * @todo
 * @mihir to move LaunchDarkly user identification to this context from DashboardContext
 * @mihir to move the user project aggregation query into a separate query
 * update types
 */

import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { GET_USER_INFO } from '../../../../graphql/queries';
import { User, UserEntitlements, UserInfoQuery } from '../../../../utils/types';
import { UserContextType } from './types';
import { getHasExceededFreeProjectsLimit, getUserEntitlents } from './utils';

export const UserContext = React.createContext<UserContextType>({
  user: null as any,
  error: null,
  loading: false,
  refetch: () => {},
  hasExceededFreeProjectsLimit: false,
  isDedicatedCloudUser: false,
  isModelBasedEnterpriseCloudUser: false,
  hasAccessToEnterprise: false,
});

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = React.useState<User>(null as any);

  const { error, loading, refetch } = useQuery<UserInfoQuery>(GET_USER_INFO, {
    onCompleted: data => {
      if (!data?.users?.length) {
        console.error('No user found', data);
        return;
      }

      setUser(data.users[0]);
    },
    fetchPolicy: 'network-only',
  });

  const userEntitlements = useMemo(() => getUserEntitlents(user), [user]);

  const hasExceededFreeProjectsLimit = useMemo<boolean>(
    () => getHasExceededFreeProjectsLimit(user),
    [user]
  );

  const isDedicatedCloudUser = useMemo<boolean>(() => {
    if (userEntitlements) {
      return !!userEntitlements[UserEntitlements.DedicatedCloudEnabled];
    }

    return false;
  }, [user]);

  const isModelBasedEnterpriseCloudUser = useMemo<boolean>(() => {
    if (userEntitlements) {
      return !!userEntitlements[
        UserEntitlements.ModelBasedEnterpriseCloudEnabled
      ];
    }

    return false;
  }, [user]);

  const hasAccessToEnterprise = useMemo(() => {
    if (isModelBasedEnterpriseCloudUser || isDedicatedCloudUser) {
      return true;
    }

    return false;
  }, [isDedicatedCloudUser, isModelBasedEnterpriseCloudUser]);

  const value = useMemo(
    () => ({
      user,
      error,
      loading,
      refetch,
      hasExceededFreeProjectsLimit,
      isDedicatedCloudUser,
      isModelBasedEnterpriseCloudUser,
      hasAccessToEnterprise,
    }),
    [user, error, loading, refetch, hasExceededFreeProjectsLimit]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
