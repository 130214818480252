import React from 'react';
import { StyledSVG } from '../Icon';

export const LaunchIcon = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width={width || '22'}
      height={height || '22'}
      viewBox="0 0 24 24"
      fill={color}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
    </StyledSVG>
  );
};
