import React from 'react';
import { Flex } from '../../atoms/Flex';
import { Text } from '../../atoms/Typography';

export const AddIcon = props => {
  const { color } = props;
  return (
    <Flex
      size={20}
      borderRadius="50%"
      bg={color || 'blue.primary'}
      justifyContent="center"
    >
      <Text color="white" fontSize="18px" pb="2.25px">
        &#43;
      </Text>
    </Flex>
  );
};
