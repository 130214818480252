// entry point
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import MakeMainRoutes from './router';
import { initSentry } from './utils/sentry';
import { initPosthog } from './utils/posthog';

import './index.css';

initSentry();
initPosthog();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<MakeMainRoutes />);
