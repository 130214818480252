import React, { useState } from 'react';
import { TextOnChange } from '../../utils/types';
import { AuthAlert } from '../Login/AuthAlert';
import { Image, Button, Flex, Heading, Input, Label, Text } from '../UIKit';
import Key from '../images/key.svg';
import {
  authEndpoints,
  EE_LITE_DEFAULT_ADMIN_USER,
} from '../../utils/constants';

interface ConfigSecretFormProps {
  loginCallback: (loginRedirect: string) => void;
  redir: string;
}

export const ConfigSecretForm: React.FC<ConfigSecretFormProps> = ({
  loginCallback,
  redir,
}) => {
  const [key, setKey] = useState('');
  const [loginErr, setLoginErr] = useState('');

  const loginUrl = authEndpoints.login;

  const handleAdminLogin = (e: React.FormEvent) => {
    e.preventDefault();

    setLoginErr('');

    const loginBody = {
      email: EE_LITE_DEFAULT_ADMIN_USER,
      password: key,
    };

    const options = {
      credentials: 'include' as 'include' | 'omit' | 'same-origin',
      body: JSON.stringify(loginBody),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    };

    return fetch(`${loginUrl}?redir=${redir}`, options)
      .then(res => res.json())
      .then(data => {
        if ('status' in data && data.status === 'failure') {
          // Invalid credentials response will include an 'error' field.
          if ('error' in data) {
            throw new Error(data.error);
          }
          // Network error / failure to connect to auth service will return response with 'message' field
          if ('message' in data) {
            throw new Error(data.message);
          }
          throw new Error(
            'Unexpected error. Get in touch with us for more info'
          );
        }
        // If there is no error, initiate the post-login redirect
        // loginCallback originates in AuthCheck.js and is passed through
        // the Login component
        loginCallback(data.location);
      })
      .catch(err => {
        if (err && err.message && err.message.includes('Failed to fetch')) {
          setLoginErr(
            `Could not reach AUTH. Please consult healthcheck service`
          );
        } else {
          setLoginErr(err);
        }
      });
  };

  return (
    <>
      <Flex mb="20px" justifyContent="space-between" minHeight="45px">
        <Heading fontSize="1.5rem" fontFamily="Poppins" id="m-sso-heading">
          EE Sign in with config secret
        </Heading>
      </Flex>
      <Flex flexDirection="column" as="form" onSubmit={handleAdminLogin}>
        <Label>
          <Image src={Key} alt="key_icon" mr="12px" width="18px" />
          <Input
            type="password"
            data-field-name={'secretKey'}
            placeholder="config secret"
            value={key}
            onChange={(e: TextOnChange) => {
              setKey(e.target.value);
            }}
          />
        </Label>
        <Button
          type="submit"
          variant="primary"
          size="large"
          width="100%"
          mt="8px"
          mb="20px"
          fontFamily="Poppins"
          disabled={!key}
          data-testid="login-btn"
        >
          Sign in with config secret
        </Button>
        {/* Auth error alert */}
        {loginErr ? (
          <>
            <AuthAlert errorMessage={loginErr} />
          </>
        ) : (
          <Text as="span" height="16px" />
        )}
      </Flex>
    </>
  );
};
