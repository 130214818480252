import * as React from 'react';
import { StyledSVG } from '../Icon';

type Props = {
  color: string;
  width: string;
  height: string;
};

export const VercelIcon: React.FC<Props> = props => {
  const { color, width, height } = props;

  return (
    <StyledSVG
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '20'}
      height={height || '20'}
      viewBox="0 0 114 114"
      fill={color}
    >
      <g
        transform="translate(0.000000,114.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M436 1124 c-204 -49 -372 -220 -421 -427 -71 -301 123 -610 428 -682
301 -71 610 123 682 428 71 301 -123 610 -428 682 -77 18 -183 18 -261 -1z
m282 -510 c73 -127 132 -234 132 -238 0 -3 -124 -6 -275 -6 -151 0 -275 2
-275 5 0 15 275 484 281 477 3 -4 65 -111 137 -238z"
        />
      </g>
    </StyledSVG>
  );
};
