import React from 'react';

import { getCurrentYear } from '../utils';

const Copyright = (): JSX.Element => {
  return (
    <span className="text-v3-copyrightText font-inter font-normal text-[14px]">
      &copy; {getCurrentYear()} Hasura Inc. All rights reserved
    </span>
  );
};

export default Copyright;
