import styled from 'styled-components';
import {
  color,
  border,
  typography,
  layout,
  space,
  background,
  shadow,
  position,
} from 'styled-system';

export const StyledInput = styled.input`
  cursor: ${props => (props.pointer ? 'pointer' : '')};
  outline: none;

  ${color}
  ${border}
  ${typography}
  ${layout}
  ${space}
  ${shadow}
  ${background}
  ${position}
`;
