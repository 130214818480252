import React from 'react';

export default function ProgressBar({ percent }: { percent: number }) {
  return (
    <div
      className="w-full animate-pulse h-9 border rounded-full"
      style={{ padding: '3px' }}
    >
      <div
        className="h-7 transition ease-in-out bg-cloudBlue rounded-full"
        // start at 10% because empty bar doesn't look like one
        style={{ width: `${percent < 90 ? percent + 10 : percent}%` }}
      />
    </div>
  );
}
