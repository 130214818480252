import React from 'react';
import { StyledSVG } from '../Icon';

export const KeyboardIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill={color}
      {...props}
    >
      <path d="M17 5H15V3H17V5ZM17 8H15V6H17V8ZM14 5H12V3H14V5ZM14 8H12V6H14V8ZM14 12H6V10H14V12ZM5 5H3V3H5V5ZM5 8H3V6H5V8ZM6 6H8V8H6V6ZM6 3H8V5H6V3ZM9 6H11V8H9V6ZM9 3H11V5H9V3ZM18 0H2C0.89 0 0 0.89 0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H18C18.5304 14 19.0391 13.7893 19.4142 13.4142C19.7893 13.0391 20 12.5304 20 12V2C20 0.89 19.1 0 18 0Z" />
    </StyledSVG>
  );
};
