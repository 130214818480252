import React from 'react';
import { css } from 'styled-components/macro';

import { theme } from '../../theme';
import { Spinner } from '../Spinner';
import { Icon } from '../../icons';
import { StyledButton } from './Button';
import { segmentTrack } from '../../../../utils/segment';

export { GithubButton } from './GithubButton';

export const Button = props => {
  const {
    children,
    size,
    disabled,
    isLoading,
    hoverColor,
    backgroundColor,
    isCreditCard,
    variant,
    height,
    color,
    iconHeight,
    fontSize,
    onClick,
    tracking,
    px,
  } = props;

  const TrackedOnClick = e => {
    if (tracking) {
      const { event, properties, options, callback } = tracking;
      segmentTrack(event, properties, options, callback);
    }
    if (onClick) onClick(e);
  };

  const { button, shadows } = theme;

  let colorValue;

  let backgroundColorValue;

  if (button[variant]) {
    colorValue = button[variant].color;
    backgroundColorValue = button[variant].backgroundColor;
  } else {
    colorValue = button.default.color;
    backgroundColorValue = button.default.backgroundColor;
  }

  let buttonHeight = height || '40px';

  if (size === 'large') {
    buttonHeight = '56px';
  }

  if (size === 'xl') {
    buttonHeight = '75px';
  }

  if (size === 'medium') {
    buttonHeight = '32px';
  }

  const spinnerSize = size === 'small' ? 'sm' : 'lg';

  let paddingX = '30px';

  if (size === 'large') {
    paddingX = 'lg';
  }

  if (size === 'small') {
    paddingX = '16px';
  }

  if (variant === 'back' || variant === 'hide') {
    return (
      <StyledButton
        {...props}
        type="back"
        bg={backgroundColor || 'transparent'}
        border="none"
        size="38px"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        hoverColor={hoverColor || '#f6f6f6'}
        // < arrow changed to >
        css={
          variant === 'hide'
            ? css`
                transform: rotate(180deg);
                padding-left: 4px;
                margin-left: -8px;
                margin-bottom: -2px;
              `
            : ``
        }
        onClick={TrackedOnClick}
      >
        <Icon
          type="back"
          fill={color || (isCreditCard && '#fff')}
          height={iconHeight}
        />
      </StyledButton>
    );
  }

  if (variant === 'div') {
    return (
      <StyledButton
        {...props}
        minHeight={buttonHeight || 'auto'}
        opacity={disabled ? '0.5' : undefined}
        border="none"
        bg="transparent"
        display="flex"
        justifyContent="center"
        alignItems="center"
        outline="none"
        hoverColor={hoverColor}
        onClick={TrackedOnClick}
      >
        {children}
        {isLoading && <Spinner size={spinnerSize} ml={18} />}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      {...props}
      minHeight={buttonHeight}
      px={px || paddingX}
      opacity={disabled ? '0.5' : undefined}
      color={colorValue}
      bg={backgroundColor || backgroundColorValue}
      fontSize={fontSize || '14px'}
      border={button[variant] ? button[variant].border : undefined}
      borderColor={button[variant] ? button[variant].borderColor : undefined}
      fontWeight="bold"
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxShadowValue={shadows.button}
      disabled={disabled && true}
      onClick={TrackedOnClick}
    >
      {children}
      {isLoading && <Spinner size={spinnerSize} ml={18} />}
    </StyledButton>
  );
};

Button.defaultProps = {
  // size: 'small',
  isLoading: false,
  disabled: false,
  borderRadius: '4px',
  'data-testid': 'btn-default-test-id',
};
