export type PasswordCheckProperty = string;

type PasswordCheckParams = {
  testExp?: RegExp;
  checker?: (value: string) => boolean;
  text: string;
  // NOTE: to be used by getPasswordValidity function
  isValid?: boolean;
};

export type PasswordConfig = Record<PasswordCheckProperty, PasswordCheckParams>;

const passwordConfig: PasswordConfig = {
  passwordLength: {
    checker: (value: string) => {
      const valLen = value.length;
      return valLen >= 8 && valLen < 64;
    },
    text: '8-64 characters',
  },
  upperLetter: {
    testExp: /[A-Z]/,
    text: '1 uppercase letter',
  },
  lowerLetter: {
    testExp: /[a-z]/,
    text: '1 lowercase letter',
  },
  number: {
    testExp: /\d/,
    text: '1 number',
  },
  specialChar: {
    testExp: /[!@#$%^&*()+-]/,
    text: '1 special character (!@#$%^&*()_+)',
  },
} as const;

const getPasswordValiditor = (config = passwordConfig) => (value: string) => {
  const getValidity = (configKey: PasswordCheckProperty) => {
    if (
      configKey &&
      'testExp' in config[configKey] &&
      typeof config[configKey].testExp !== 'undefined'
    ) {
      return (config[configKey].testExp as any).test(value);
    }

    if (
      configKey &&
      'checker' in config[configKey] &&
      typeof config[configKey].checker !== 'undefined'
    ) {
      return (config[configKey].checker as any)(value);
    }

    return false;
  };

  return Object.keys(config).reduce((acc, configKey) => {
    return {
      ...acc,
      [configKey]: {
        ...config[configKey],
        isValid: getValidity(configKey),
      },
    };
  }, {} as PasswordConfig);
};

export default getPasswordValiditor;
