// twjoin helps join tailwind classes dynamically
// NOTE: use wisely since it might affect performance
// of the webpage if it is misused
function twjoin(...args: string[]) {
  return args
    .filter(Boolean)
    .map(arg => arg.trim())
    .join(' ');
}

const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

const getRedir = (search: string) => {
  const parsed = new URLSearchParams(search);
  let uri = '/';

  if (parsed.has('redirect_url')) {
    uri = (parsed.get('redirect_url') as unknown) as string;
  } else if (parsed.has('login_challenge')) {
    uri = new URL(window.location.href).toString();
  }

  return window.encodeURIComponent(uri as 'string');
};

export { twjoin, getCurrentYear, getRedir };
