import React from 'react';
import { useLocation } from 'react-router-dom';

import { Tabs } from './components';
import { Logo } from './images';
import { twjoin } from './utils';
import { paths } from '../../utils/routeUtils';
import { SignUp } from './pages';
import { LandingPageProps } from './pages/SignUp';
import { Banner } from '../Banners';

import { PiThumbsUp } from 'react-icons/pi';
import { useAuthModes } from '../../hooks';

const onClickLogo = () => {
  if (window?.open) {
    window.open('https://hasura.io/', '_blank', 'noopener')?.focus();
  }
};
const NoCreditCardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="credit-card-x">
      <path
        id="Icon"
        d="M10.9987 10.6667L14.332 14M14.332 10.6667L10.9987 14M14.6654 6.66671H1.33203M14.6654 8.00004V5.46671C14.6654 4.71997 14.6654 4.3466 14.52 4.06139C14.3922 3.81051 14.1882 3.60653 13.9374 3.4787C13.6521 3.33337 13.2788 3.33337 12.532 3.33337H3.46537C2.71863 3.33337 2.34526 3.33337 2.06004 3.4787C1.80916 3.60653 1.60519 3.8105 1.47736 4.06139C1.33203 4.3466 1.33203 4.71997 1.33203 5.46671V10.5334C1.33203 11.2801 1.33203 11.6535 1.47736 11.9387C1.60519 12.1896 1.80916 12.3936 2.06004 12.5214C2.34526 12.6667 2.71863 12.6667 3.46536 12.6667H7.9987"
        stroke="#6C737F"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

const CheckedIcon = () => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.599998" width="20.8" height="20.8" rx="10.4" fill="#80A3FF" />
    <path
      d="M14.6661 7.8L8.79948 13.6667L6.13281 11"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const V3SignUpHeader = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <h5 className="font-inter text-[17.5px] sm:text-[20px] leading-[32px] font-semibold mb-4 md:self-center">
      {children}
    </h5>
  );
};

const DdnLoginPage = (props: LandingPageProps): JSX.Element => {
  const { pathname } = useLocation();

  const showSignInContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.register.newUserCloud() &&
      pathname !== paths.v3.login.forgotPassword()
    );
  }, [pathname]);

  const showSignUpContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.login.forgotPassword() &&
      pathname === paths.v3.register.newUserCloud()
    );
  }, [pathname]);

  const CheckBadge = () => {
    return (
      <div className="flex items-center justify-center rounded-full w-6 h-6">
        <CheckedIcon />
      </div>
    );
  };
  type Feature = {
    Icon: React.ComponentType;
    text: string;
  };
  const features: Feature[] = [
    {
      Icon: CheckBadge,
      text: 'Ship incredible APIs - in minutes',
    },
    {
      Icon: CheckBadge,
      text: 'Run at scale - we handle the ops',
    },
    {
      Icon: CheckBadge,
      text: 'Generous free tier with no API usage limits',
    },
  ];

  const { allowedAuthModes } = useAuthModes();

  const getValidPathName = () => {
    if (
      pathname === paths.v3.register.newUserCloud() ||
      pathname === paths.v3.login.forgotPassword()
    )
      return pathname;

    if (!allowedAuthModes.length) return null;

    const isCurrentPathNameAllowed = !!allowedAuthModes.find(
      a => a.route === pathname
    );

    if (isCurrentPathNameAllowed) return pathname;

    const firstValidPathname = allowedAuthModes[0].route;

    return firstValidPathname;
  };

  const validPathName = getValidPathName();

  const FeatureItem = ({ feature }: { feature: Feature }) => {
    return (
      <div className="self-stretch justify-start gap-3 inline-flex text-xs sm:text-xs md:text-xs lg:text-xs xl:text-md">
        <div className="mt-1">
          <feature.Icon />
        </div>
        <div className="grow shrink basis-0 text-[#18222f] text-xl font-normal font-['Inter'] leading-normal">
          {feature.text}
        </div>
      </div>
    );
  };
  const AuthSection = () => (
    <div className="rounded-tr-3xl w-full px-4 1080p:max-w-[500px] rounded-br-3xl flex-col justify-center items-center gap-3 inline-flex h-full">
      <div
        className={twjoin(
          'bg-white h-full w-full z-0 relative',
          'flex flex-col md:max-w-lg w-full border border-v3-menuBorder px-5 py-5 sm:px-8 sm:py-8 lg:px-16 lg:py-14 ',
          'rounded-2xl md:justify-start justify-center min-h-0'
          // pathname === paths.v3.register.newUserCloud()
          //   ? 'lg:px-16 '
          //   : 'lg:p-16'
        )}
      >
        {showSignInContentHeading ? (
          <V3SignUpHeader>Sign in to your account</V3SignUpHeader>
        ) : null}
        {showSignUpContentHeading ? (
          <V3SignUpHeader>Create a new Hasura Account</V3SignUpHeader>
        ) : null}
        {validPathName ? (
          <>
            {showSignInContentHeading ? (
              <Tabs currentRoute={validPathName} />
            ) : null}
            <SignUp currentRoute={validPathName} landingProps={props} />
          </>
        ) : (
          <div className="px-4 py-3 text-sm rounded-md bg-red-100 justify-start items-center gap-2">
            No <code className="display-inline">AUTH_METHODS</code> detected in
            your configuration.
            <br />
            Please contact you administrator
          </div>
        )}
      </div>
      <div className="w-full justify-between items-start gap-2 sm:gap-6 flex flex-wrap mb-1 mt-4 lg:mt-4">
        {[
          {
            text: 'Generous free tier',
            Icon: PiThumbsUp,
          },
          {
            text: 'No credit card required',
            Icon: NoCreditCardIcon,
          },
        ].map(({ text, Icon }) => (
          <div className="h-6 justify-start items-center gap-6 flex">
            <div className="justify-start items-center gap-1 flex">
              <div className="p-1 rounded-[32px] justify-center items-center gap-1 flex">
                <Icon />
              </div>
              <div className="text-[#18222F] text-sm font-medium font-['Inter'] leading-tight">
                {text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const ContentSection = () => (
    <div
      className={twjoin(
        'flex w-full flex-col 1080p:w-3/5 items-center 1080p:items-start',
        'mb-5 md:mb-0'
      )}
    >
      <div className="hover:cursor-pointer pb-1 md:pb-12" onClick={onClickLogo}>
        <Logo />
      </div>
      <div className="self-stretch flex-col justify-start items-start gap-10 hidden 1080p:flex ">
        <div className="self-stretch flex-col justify-start items-start gap-6 flex">
          <div className="flex flex-col items-center mt-4 md:mt-0 md:items-start md:flex-row md:block">
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-[#000615] font-semibold font-['Inter'] leading-loose">
                <h1 className="text-4xl max-w-[347px] tracking-tight leading-snug">
                  Try Hasura today. It’s absolutely free 🫰🏼
                </h1>
              </div>
            </div>
          </div>
          <div className="self-stretch h-44 flex-col justify-start items-start gap-4 flex">
            {features.map((feature, index) => (
              <FeatureItem key={index} feature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-screen bg-v3-background">
      <div
        className="bg-v3-background z-0 py-6 md:py-0 w-screen flex items-center justify-center flex-col md:h-screen"
        id="new-sign-up-page-root"
      >
        <Banner />
        <div
          className={twjoin(
            'font-inter z-[1]',
            'flex  md:rounded-[24px] justify-between flex-col w-screen lg:w-5/6 md:w-[95%] gap-8 lg:gap-10 mt-0 1080p:flex-row max-w-[1280px] max-h-[900px]',
            pathname === paths.v3.register.newUserCloud()
              ? 'h-[90%]'
              : 'h-[90%]'
          )}
        >
          <ContentSection />
          <AuthSection />
        </div>
        <div className="fixed left-0 bottom-[10%] z-[0] lgHeight:bottom-[5%] hidden 1080p:block smHeight:hidden">
          <img
            className="max-w-[42%] lg:max-w-[48%] xl:max-w-[50%] mdHeight:max-w-[60%]"
            src="https://res.cloudinary.com/dh8fp23nd/image/upload/v1726146122/cloud/migration_ftn9c4.png"
            alt="Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default DdnLoginPage;
