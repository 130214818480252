import styled from 'styled-components';
import { color, typography, layout, space } from 'styled-system';

export const StyledSVG = styled.svg`
  cursor: ${props => (props.pointer ? 'pointer' : '')};

  ${color}
  ${typography}
  ${layout}
  ${space}
`;
