import React from 'react';

export default function ({ text }: { text?: string }) {
  return (
    <div style={{ height: '20px' }} className="mb-2">
      {text && (
        <span className="text-sm font-semibold text-gray-500 bg-white pr-2 mb-2">
          {text}
        </span>
      )}
      <hr
        style={{ marginTop: '-10px' }}
        className="w-full border-t-0 border-b border-solid border-gray-400"
      />
    </div>
  );
}
