import styled from 'styled-components';
import { typography, color, space, border, layout } from 'styled-system';

export const StyledLink = styled.a`
  cursor: ${props => props.cursor || 'pointer'};
  color: inherit;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : '')};

  &&& {
    text-decoration: none;
  }

  &:hover {
    color: ${props => props.hoverColor};
  }
  
  ${typography}
  ${color}
  ${space}
  ${border}
  ${layout}
`;
