import React from 'react';
import { hasuraCon23BannerLS } from './utils';
import HasuraConImg from './hasura.svg';
const hasuraConUrl = 'https://hasura.io/events/hasura-con-2024';
const registrationUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSdK969zJfqlb1x30AHHYS5ui7zSoyiKQso2qNbiqwnNavBlsg/viewform';

export default function HasuraConBanner() {
  const [dismissed, setDismissed] = React.useState(false);

  function dismissBanner() {
    setDismissed(true);
    hasuraCon23BannerLS.dismiss();
  }

  if (!hasuraCon23BannerLS.isDismissed() && !dismissed)
    return (
      <div className="w-full">
        <div
          style={{
            background: '#111927',
          }}
          className="flex top-0 min-h-[60px] items-center justify-between px-[6%] border-bottom border-bottom-gray-200 "
        >
          <a target="_blank" href={hasuraConUrl} rel="noopener noreferrer">
            <img
              className="h-[27px] max-h-[27px] max-w-[100%]"
              alt="hasura-con-23"
              loading="lazy"
              src={HasuraConImg}
            />
          </a>
          <div className="flex w-full justify-center items-center">
            <a
              target="_blank"
              href={registrationUrl}
              rel="noopener noreferrer"
              className="flex justify-start font-semibold decoration-none text-white"
            >
              <p className="hover:mr-1">
                The HasuraCon 2024 CFP is open! Submit your talk
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 18 18"
                fill="none"
                id="right_chevron"
                className="hover:ml-1"
              >
                <path
                  d="M3.375 9L13.5 9"
                  stroke="#80A3FF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.1875 14.0625L14.2501 8.99995L9.1875 3.9374"
                  stroke="#80A3FF"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <button
            data-testid="dismiss-hasuracon23-banner"
            onClick={dismissBanner}
          >
            <svg
              className="cursor-pointer"
              fill="#80A3FF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16px"
              height="16px"
            >
              <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
            </svg>
          </button>
        </div>
      </div>
    );

  return null;
}
