import styled, { css } from 'styled-components';
import {
  typography,
  color,
  space,
  border,
  layout,
  shadow,
  position,
} from 'styled-system';

import { textEllipses, getCursorType, hoverStyles } from '../utils';

export const StyledHeading = styled.h1`
    white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};

    ${typography}
    ${color}
    ${space}
    ${layout}
    ${border}
    ${shadow}
    ${position}
    
    ${getCursorType}
`;

export const StyledText = styled.p`
    letter-spacing: inherit;

    /* white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : '')}; */

    white-space: ${({ nowrap, breakLine }) => {
      if (nowrap) {
        return 'nowrap';
      }

      if (breakLine) {
        return 'pre-wrap';
      }
      return '';
    }};

    word-break: ${({ breakWord }) => (breakWord ? 'break-all' : '')};

    /* TODO: Refactor it to a separate util function */
    ${({ textTransform }) =>
      textTransform &&
      css`
        text-transform: ${textTransform};
      `}

    ${typography}
    ${color}
    ${space}
    ${border}
    ${layout}
    ${shadow}
    ${position}

    ${getCursorType}
    ${textEllipses}
    ${hoverStyles}
`;
