import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthCheck from './AuthCheck';
import { ThemeProvider } from 'styled-components';
import { paths } from './utils/routeUtils';

import { theme } from './components/UIKit';
import { GlobalStyles } from './components/styles/GlobalStyles';
import NotificationsProvider from './components/Notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const MakeMainRoutes: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <GlobalStyles />
          <NotificationsProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path={paths.rootWithChildren()}
                  element={<AuthCheck />}
                />
              </Routes>
            </BrowserRouter>
          </NotificationsProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ThemeProvider>
  );
};

export default MakeMainRoutes;
