import React, { SVGProps } from 'react';

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={24}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.43 16.199a3.334 3.334 0 0 1 3.07-2.032h5c1.38 0 2.563.837 3.07 2.032m-2.237-8.282a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0Zm5 2.083a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0Z"
      stroke="#3970FD"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileIcon;
