import React from 'react';
import { StyledSVG } from '../Icon';

export const EditIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      {...props}
    >
      <path d="M15.74 3.59283C16.0867 3.24622 16.0867 2.66852 15.74 2.33967L13.6603 0.259964C13.3315 -0.0866546 12.7538 -0.0866546 12.4072 0.259964L10.7718 1.8864L14.1047 5.21927L15.74 3.59283ZM0 12.6671V16H3.33287L13.1626 6.16137L9.82975 2.8285L0 12.6671Z" />
    </StyledSVG>
  );
};
