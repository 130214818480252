import styled from 'styled-components/macro';

export const StyledPasswordInput = styled.div`
  width: 100%;

  .strength-meter {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
  }

  .strength-meter:before,
  .strength-meter:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(33% + 6px);
    z-index: 10;
  }

  .strength-meter:before {
    left: calc(33% - 3px);
  }

  .strength-meter:after {
    right: calc(33% - 3px);
  }

  .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
  }

  .strength-meter-fill[data-strength='0'] {
    width: 33%;
    background: red;
  }

  .strength-meter-fill[data-strength='1'] {
    width: 66%;
    background: yellowgreen;
  }

  .strength-meter-fill[data-strength='2'] {
    width: 100%;
    background: green;
  }
`;

export const StyledTooltipMessage = styled.span`
  display: inline-block;
  text-align: left;
  padding-left: 5px;
`;
