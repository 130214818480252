import React from 'react';

import { CheckedTick, UncheckedTick } from '../images';
import { twjoin } from '../utils';
import getPasswordValidator, {
  PasswordConfig,
} from '../passwordValidity.utils';

const PasswordCheck = (props: {
  checked: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div className="flex flex-row my-1 items-center">
      {props.checked ? <CheckedTick /> : <UncheckedTick />}
      <span
        className={twjoin(
          'ml-[10px]',
          props.checked ? 'text-[#384250]' : 'text-[#6C737F]'
        )}
      >
        {props.children}
      </span>
    </div>
  );
};

type PasswordStrengthMeterProps = {
  value: string;
};

const PasswordStrength = ({
  value,
}: PasswordStrengthMeterProps): JSX.Element => {
  const passwordValidator = getPasswordValidator();
  const [
    currentPasswordValidationState,
    updatePasswordValidationState,
  ] = React.useState<PasswordConfig>();

  React.useEffect(() => {
    if (value) {
      updatePasswordValidationState(passwordValidator(value));
    }
  }, [value]);

  const passwordValidationContent = React.useMemo(() => {
    const isValid = (configKey: string) => {
      return (
        (currentPasswordValidationState &&
          currentPasswordValidationState[configKey].isValid) ??
        false
      );
    };

    const configKeyText = (configKey: string) => {
      return (
        (currentPasswordValidationState &&
          currentPasswordValidationState[configKey].text) ??
        ''
      );
    };

    return Object.keys(currentPasswordValidationState ?? {}).map(configKey => (
      <PasswordCheck
        checked={isValid(configKey)}
        key={`password-strength-${configKey}`}
      >
        {configKeyText(configKey)}
      </PasswordCheck>
    ));
  }, [currentPasswordValidationState]);

  return (
    <div className="flex flex-col mt-2">
      <div className="flex flex-col font-inter">
        <h3 className="text-[#6C737F] font-medium text-base mb-2">
          Password must include
        </h3>
        <div className="flex flex-col">{passwordValidationContent}</div>
      </div>
    </div>
  );
};

export default PasswordStrength;
