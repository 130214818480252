import React from 'react';
import { StyledSVG } from '../Icon';

export const Enterprise = props => {
  const { color } = props;

  return (
    <StyledSVG
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1H3V15.0909H13V1ZM8.90913 2.81818H11.1819V5.09091H8.90913V2.81818ZM4.81823 2.81818H7.09095V5.09091H4.81823V2.81818ZM4.81813 6.90907H7.09085V9.1818H4.81813V6.90907ZM7.09085 11H4.81813V13.2727H7.09085V11ZM11.1819 6.90907H8.90913V9.1818H11.1819V6.90907ZM8.90913 11H11.1819V13.2727H8.90913V11Z"
        fill={color}
      />
    </StyledSVG>
  );
};
