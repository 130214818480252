import { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { RuntimeErrorKind } from './types';

import { EE_MODE, IS_PRODUCTION, SENTRY_DSN } from './constants';

// Take in regexes to match against the error URLs
// This is a list of sentry recommended error URLs to ignore:
// https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
const commonGraphQLErrorURLs = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

const sentryOptions = {
  dsn: SENTRY_DSN,
  denyUrls: commonGraphQLErrorURLs,
};

export const initSentry = () => {
  if (IS_PRODUCTION && !EE_MODE) {
    Sentry.init(sentryOptions);
  }
};

export const trackException = (e: Error) => {
  if (IS_PRODUCTION && !EE_MODE) {
    Sentry.captureException(e);
  }
};

const commonGraphqlErrors = [
  'has invalid combination of characters',
  'incorrect length',
  'not allowed to perform this operation',
  'invalid invitation key',
  'email provided is already associated',
  'new email given is same',
  'invalid coupon',
  'already a card on file',
  'collaborators limit reached',
  'duplicate key value',
  'request already submitted',
  // tenant name unavailability
  `not available, it's already in use`,
  // reserved env prefix
  'the HASURA_GRAPHQL_ prefix is reserved by Hasura Cloud',
  // Heroku Db
  'Unauthorized',
  'is same as input',
  'invalid project id',
  'conformation mail limit is reached',
  'not been confirmed',
  'confirmation limit',
  // GraphQL Error: heroku: get session: secret nil
  'secret nil',
  // Happens for illegal invocation in sendBeacon inbrowser for Posthog and Munchkin
  'Illegal invocation',
  // Heroku resetting credentials security incident
  'heroku: unregister webhook: deleting webhook: heroku error (code 401): unauthorized: Invalid credentials provided',
];

const checkCommonError = (currentError: string) => {
  let isCommonError;

  // filtering common graphql errors

  commonGraphqlErrors.forEach(error => {
    if (currentError.includes(error)) {
      isCommonError = true;
    }
  });

  return isCommonError;
};

// The following array defines what error kinds from the error contract
// returned from Lux back-end should be avoided getting tracked on Sentry
const errorKindsToAvoidTrackingOnSentry = ['PreconditionFailed'];

export const trackGraphQLError = (e: GraphQLError) => {
  if (IS_PRODUCTION) {
    const errorMessage = e.message;
    const isErrorKindAllowed = !errorKindsToAvoidTrackingOnSentry.includes(
      e?.extensions?.code
    );

    if (!checkCommonError(errorMessage) && isErrorKindAllowed) {
      Sentry.captureMessage(`GraphQL Error: ${errorMessage}`, 'fatal');
    }
  }
};

export const trackCustomError = (message: string, pathname: string) => {
  if (IS_PRODUCTION) {
    Sentry.captureMessage(
      `{ pathname: ${pathname}, message: ${message} }`,
      'info'
    );
  }
};

const errorCache: Record<
  RuntimeErrorKind,
  Record<string, boolean | undefined>
> = {
  'config-errors': {},
};

export const useRenderEventTracking = ({
  error = '',
  kind,
  skip,
}: {
  error: any;
  kind: RuntimeErrorKind;
  skip: boolean;
}) => {
  React.useEffect(() => {
    if (IS_PRODUCTION && !skip && error) {
      const stringified = JSON.stringify(error);
      if (stringified && !errorCache[kind][stringified]) {
        Sentry.captureMessage(
          `{ kind: ${kind}, message: ${stringified} }`,
          'info'
        );
        errorCache[kind][stringified] = true;
      }
    }
  }, [error, skip]);
};

export const sentryIdentify = (userId: string) => {
  if (IS_PRODUCTION) {
    Sentry.setUser({
      id: userId,
      ip_address: '{{auto}}',
    });
  }
};

export const sentryResetUser = () => {
  if (IS_PRODUCTION) {
    Sentry.setUser(null);
  }
};
