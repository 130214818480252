import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { authEndpoints } from '../../../utils/constants';
import {
  validateEmail,
  isValidPassword,
  parseAuthResponse,
  getPasswordStrength,
} from '../../../utils/helpers';
import { paths } from '../../../utils/routeUtils';
import { NotificationsContext } from '../../Notifications';
import { Input, PrimaryButton, PasswordStrength } from '../components';
import { useForm, useOnKeyPressEnter } from '../hooks';
import { twjoin } from '../utils';

const formConfig = {
  firstName: {
    default: '',
    validator: (value: unknown) => {
      return typeof value === 'string' && value.length !== 0;
    },
  },
  lastName: {
    default: '',
    validator: (value: unknown) => {
      return typeof value === 'string';
    },
  },
  email: {
    default: '',
    validator: (value: unknown) => {
      if (value === '') {
        return true;
      }

      if (!value) {
        return false;
      }

      if (typeof value !== 'string') {
        return false;
      }

      return validateEmail(value);
    },
  },
  organization: {
    default: '',
  },
  password: {
    default: '',
    validator: (value: unknown) => {
      if (value === '') {
        return true;
      }

      if (!value) {
        return false;
      }

      if (typeof value !== 'string') {
        return false;
      }

      return isValidPassword(value);
    },
  },
  checkTermsAndConditions: {
    default: false,
  },
} as const;

const RegisterEmailPassword = (): JSX.Element => {
  const [registerFormState, onChangeHandler] = useForm(formConfig);
  const { showErrorNotification, showSuccessNotification } = React.useContext(
    NotificationsContext
  );
  const navigate = useNavigate();
  const { search } = useLocation();
  const isEnterPressed = useOnKeyPressEnter();
  const [requestLoadingStatus, updateRequestLoadingStatus] = React.useState(
    false
  );

  const isRequiredInputsFilledAndValid = React.useMemo(() => {
    return (
      Boolean(
        registerFormState.firstName.value && registerFormState.firstName.isValid
      ) &&
      Boolean(registerFormState.lastName.isValid) &&
      Boolean(
        registerFormState.email.value && registerFormState.email.isValid
      ) &&
      Boolean(
        registerFormState.password.value && registerFormState.password.isValid
      ) &&
      Boolean(registerFormState.checkTermsAndConditions.value)
    );
  }, [
    registerFormState.checkTermsAndConditions.value,
    registerFormState.email.value,
    registerFormState.firstName.value,
    registerFormState.lastName.value,
    registerFormState.password.value,
  ]);

  const onClickRegister = () => {
    const registerBody = {
      first_name: registerFormState.firstName.value.trim(),
      last_name: registerFormState.lastName.value.trim(),
      email: registerFormState.email.value.toLowerCase().trim(),
      organization: registerFormState.organization.value.trim(),
      password: registerFormState.password.value.trim(),
      confirm_password: registerFormState.password.value.trim(),
    };

    const options: RequestInit = {
      credentials: 'include',
      body: JSON.stringify(registerBody),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    };

    let requestUrl = authEndpoints.signup;
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect_url');
    const loginChallenge = params.get('login_challenge');

    if (
      redirectUrl &&
      (redirectUrl.includes('console.hasura.io') ||
        redirectUrl.includes('console.arusah.com'))
    ) {
      const queryStr = new URLSearchParams({ redirect_url: redirectUrl });

      const platform = redirectUrl.includes('promptql')
        ? 'promptql_v3_console'
        : 'hasura_v3_console';

      requestUrl = `${requestUrl}?${queryStr}?platform=${platform}`;
    }
    if (loginChallenge) {
      const queryStr = new URLSearchParams({ login_challenge: loginChallenge });
      requestUrl = `${requestUrl}?${queryStr}`;
    }

    updateRequestLoadingStatus(true);

    return fetch(requestUrl, options)
      .then(resp => {
        updateRequestLoadingStatus(false);
        return resp.json();
      })
      .then(resp => {
        if ('status' in resp && resp.status === 'failure') {
          showErrorNotification(
            `Sign Up Error: ${parseAuthResponse(resp.errors)}`
          );
        }
        if ('status' in resp && resp.status === 'success') {
          showSuccessNotification(
            'Sign Up success! Check your email to confirm your account and login!',
            null,
            '',
            undefined
          );
          navigate({
            pathname: paths.signup(),
            search,
          });
        }
      })
      .catch(err => {
        showErrorNotification(`Sign Up Error: ${err}`);
      });
  };

  const passwordStrength = React.useMemo(() => {
    if (registerFormState.password.value === formConfig.password.default) {
      return -1;
    }
    return getPasswordStrength(registerFormState.password.value);
  }, [registerFormState.password.value]);

  React.useEffect(() => {
    if (isEnterPressed && isRequiredInputsFilledAndValid) {
      onClickRegister();
    }
  }, [isEnterPressed]);

  const passwordBarColors = React.useMemo(() => {
    const palette = {
      grey: 'bg-v3-status-grey',
      red: 'bg-v3-status-red',
      orange: 'bg-v3-status-orange',
      green: 'bg-v3-status-green',
    };

    let colors = [palette.grey, palette.grey, palette.grey];

    if (passwordStrength === 0 || passwordStrength === 99) {
      colors[0] = palette.red;
      colors[1] = palette.grey;
      colors[2] = palette.grey;
    } else if (passwordStrength === 1) {
      colors[0] = palette.orange;
      colors[1] = palette.orange;
      colors[2] = palette.grey;
    } else if (passwordStrength === 2) {
      colors = colors.map(() => palette.green);
    }

    return colors;
  }, [passwordStrength]);

  return (
    <div className="flex flex-col h-full justify-between p-0 m-0 min-h-0">
      <div className="flex flex-col overflow-y-auto">
        <Input
          placeholder="First Name"
          type="text"
          value={registerFormState.firstName.value}
          onChange={onChangeHandler('firstName')}
          isErrored={!registerFormState.firstName.isValid}
          errorText="Please enter a valid first name (1-30 characters)"
          data-testid="register-first-name-input"
        />
        <Input
          placeholder="Last Name"
          type="text"
          elementStyles="mt-3"
          value={registerFormState.lastName.value}
          onChange={onChangeHandler('lastName')}
          isErrored={!registerFormState.lastName.isValid}
          errorText="Please enter a valid last name (1-30 characters)"
          data-testid="register-last-name-input"
        />
        <Input
          placeholder="Work email"
          type="email"
          elementStyles="mt-3"
          value={registerFormState.email.value}
          onChange={onChangeHandler('email')}
          isErrored={!registerFormState.email.isValid}
          errorText="Please enter a valid email address"
          data-testid="register-email"
        />
        <Input
          placeholder="Organization Name"
          type="text"
          elementStyles="mt-3"
          value={registerFormState.organization.value}
          onChange={onChangeHandler('organization')}
          data-testid="register-organization"
        />
        <Input
          placeholder="Password"
          type={'password'}
          elementStyles="mt-3"
          value={registerFormState.password.value}
          onChange={onChangeHandler('password')}
          isErrored={!registerFormState.password.isValid}
          errorComponent={
            <PasswordStrength value={registerFormState.password.value} />
          }
          data-testid="register-password"
          child={
            <div className="grid grid-cols-3 gap-3 my-2">
              <span className={twjoin('h-1', passwordBarColors[0])} />
              <span className={twjoin('h-1', passwordBarColors[1])} />
              <span className={twjoin('h-1', passwordBarColors[2])} />
            </div>
          }
        />
        <div className="flex font-inter mt-2">
          <input
            id="link-checkbox"
            type="checkbox"
            checked={registerFormState.checkTermsAndConditions.value}
            onChange={onChangeHandler('checkTermsAndConditions')}
            className="w-6 h-6 border-2 border-[#9DA4AE] rounded"
            data-testid="register-terms-checkbox"
          />
          <label
            htmlFor="link-checkbox"
            className="ml-2 text-[14px] sm:text-[15px] text-[#6C737F] leading-[150%] dark:text-gray-300 mt-[2px]"
          >
            By signing up, you agree to our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hasura.io/legal/hasura-cloud-terms-of-service/"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              Terms of Service
            </a>{' '}
            &amp;{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://hasura.io/legal/hasura-privacy-policy/"
              className="text-blue-600 dark:text-blue-500 hover:underline"
            >
              Privacy Policy
            </a>
            .
          </label>
        </div>
      </div>
      <div className="flex">
        <PrimaryButton
          disabled={!isRequiredInputsFilledAndValid}
          onClick={onClickRegister}
          variant="primary"
          style={{ marginTop: '12px', marginBottom: '0px' }}
          loading={requestLoadingStatus}
          id="submit-register"
        />
      </div>
    </div>
  );
};

export default RegisterEmailPassword;
