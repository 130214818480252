import moment from 'moment';
import * as React from 'react';

const isValidDate = (dateString: string) => {
  const d = new Date(dateString);

  return d instanceof Date && !Number.isNaN(d);
};

// ***************************** //

export const getTimeDistanceFromNow = (timeString: string) => {
  if (timeString && isValidDate(timeString)) {
    return moment(timeString).fromNow();
  }

  return 'Not logged in yet';
};

// ***************************** //

/*  getFormattedDate helper function
 *  output -> 24 May 2020, 23:09:40
 */

export const getFormattedDate = (dateString: string) => {
  if (dateString && isValidDate(dateString)) {
    return moment(dateString).format('MMMM Do YYYY, h:mm:ss a');
  }

  return 'Not logged in yet';
};

// ***************************** //

export const getProjectExpiryDeadline = () => {
  const today = moment();
  const projectExpireDate = moment([2020, 7, 31]);

  return projectExpireDate.diff(today, 'days');
};

// returns a state variable that updates every <interval> ms.
// default 1000 ms
export const useCurrentTime = (interval = 1000) => {
  let timer: NodeJS.Timeout;

  const [time, setTime] = React.useState(new Date());

  React.useEffect(
    React.useCallback(() => {
      timer = setInterval(() => {
        setTime(new Date());
      }, interval);
      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }, []),
    []
  );

  return time;
};

// ***************************** //

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getCreatedAt = (timeString: string) => {
  if (timeString && isValidDate(timeString)) {
    return moment(timeString).fromNow();
  }

  return null;
};

// ***************************** //

/*  getFormattedDateAndTime helper function
    output -> 20210915080317
    YYYYMMDDHHMMSS for for 15th Sept. 2021, 08:03:17
*/

export const getFormattedDateAndTime = () => {
  return new Date()
    .toISOString()
    .slice(0, 19)
    .replace(/[^0-9]/g, '');
};
