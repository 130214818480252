import React from 'react';

import { Button, Image } from '../index';
import githubCTALogo from '../../../images/github-min-logo.svg';

interface GithubButtonProps {
  width: string;
  mr?: string;
  id?: string;
  dataTestId?: string;
  children?: React.ReactNode;
}

export const GithubButton: React.FC<GithubButtonProps> = ({
  width,
  children,
  mr,
  id,
  dataTestId,
}) => (
  <Button
    variant="github_secondary"
    id={id || 'github-button'}
    data-testid={dataTestId || 'github-button'}
    mt="25px"
    minWidth={width}
    width={width}
    mr={mr}
  >
    <Image
      src={githubCTALogo}
      alt="Github"
      minWidth="18px"
      width="18px"
      // We only need right spacing in case of CTA text.
      mr={children ? '7px' : '0'}
    />
    {children}
  </Button>
);
