import React from 'react';
import { StyledSVG } from '../Icon';

export const MaintenanceIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill={color}
      {...props}
    >
      <g>
        <g>
          <rect
            x="16.3"
            y="12.9"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.8725 17.627)"
            className="st1"
            width="3"
            height="8.5"
          />
          <path
            className="st1"
            d="M17.5,10c1.9,0,3.5-1.6,3.5-3.5c0-0.6-0.2-1.1-0.4-1.6l-2.7,2.7l-1.5-1.5l2.7-2.7c-0.5-0.2-1-0.4-1.6-0.4
			C15.6,3,14,4.6,14,6.5c0,0.4,0.1,0.8,0.2,1.2l-1.9,1.9l-1.8-1.8L11.3,7L9.9,5.6L12,3.5c-1.2-1.2-3.1-1.2-4.2,0L4.2,7l1.4,1.4H2.8
			L2.1,9.1l3.5,3.5L6.3,12V9.1l1.4,1.4l0.7-0.7l1.8,1.8L2.8,19L5,21.2L16.3,9.8C16.7,9.9,17.1,10,17.5,10z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};
