import { gql } from '@apollo/client';
import {
  DEDICATED_VPC_FRAGMENT,
  LABEL_FRAGMENT,
  PROJECTS_LIST_FRAGMENT,
  PROJECT_FRAGMENT,
  PROJECT_LIST_BASE_FRAGMENT,
  REGION_FRAGMENT,
  TENANT_FRAGMENT,
  VPC_PEERING_FRAGMENT,
} from '../fragments';

export const GET_PROJECT_DETAILS = gql`
  ${PROJECT_FRAGMENT}
  ${LABEL_FRAGMENT}
  query getProjectDetails($id: uuid!) {
    project: projects_by_pk(id: $id) {
      ...ProjectFields
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
  }
`;

export const GET_SRE_SUPPORT_PROJECTS_LIST = gql`
  query getSupportProjectsList {
    projects(where: { plan_name: { _neq: "pro" } }) {
      id
      name
      plan_name
    }
  }
`;

export const GET_PROJECT = gql`
  ${PROJECT_FRAGMENT}
  query getProjectTenantDetails($id: uuid!) {
    project: projects_by_pk(id: $id) {
      ...ProjectFields
    }
  }
`;

export const GET_ENV_VARS = gql`
  query getTenantEnv($id: uuid!) {
    getTenantEnv: getTenantEnv(tenantId: $id) {
      hash
      envVars
    }
  }
`;

export const FETCH_INVITATIONS = gql`
  query fetchPendingInvitations {
    fetchPendingInvitations {
      id
      invitedAt
      projectName
      invitedBy
      key
      privileges
      isBillable
      projectID
    }
  }
`;

export const GET_ADMIN_SECRET = gql`
  query getAdminSecret($id: uuid!) {
    getTenantEnv(tenantId: $id) {
      hash
      envVars
    }
  }
`;

export const REALTIME_TENANT_STATUS = gql`
  ${TENANT_FRAGMENT}
  subscription realtimeTenantStatus($tenantId: uuid!) {
    tenant: tenant_by_pk(id: $tenantId) {
      ...TenantFields
    }
  }
`;

export const GET_CUSTOM_DOMAINS = gql`
  subscription getCustomDomains($tenantId: uuid!) {
    domains: custom_domain(
      where: { tenant_id: { _eq: $tenantId } }
      order_by: { created_at: desc }
    ) {
      id
      fqdn
      dns_validation
      ssl_status
      ssl_message
    }
  }
`;

export const GET_USAGE_DETAILS = gql`
  subscription getUsageDetails($id: uuid!) {
    projects(where: { id: { _eq: $id } }) {
      plan_name
      created_at
      collaborators_aggregate {
        aggregate {
          count
        }
      }
      policies {
        log_retention
        caching_enabled
      }
    }
  }
`;

export const GET_REGIONS = gql`
  ${REGION_FRAGMENT}
  query getRegions {
    region(where: { is_active: { _eq: true } }) {
      ...RegionFields
    }
    region_v2(where: { is_active: { _eq: true } }) {
      region {
        ...RegionFields
      }
    }
  }
`;

export const GET_LABEL_SUGGESTIONS = gql`
  query getLabelSuggestions($name: String!) {
    label(where: { name: { _like: $name } }) {
      name
    }
  }
`;

export const VALIDATE_TENANT_NAME = gql`
  query validateTenantName($name: String!) {
    validTenantName(name: $name) {
      message
      valid
    }
  }
`;

export const FETCH_OWNERSHIP_TRANSFER_INVITATIONS = gql`
  query fetchPendingOwnershipInvitations {
    fetchPendingOwnershipInvitations {
      id
      invitedAt
      invitedBy
      key
      projectName
      projectPlan
    }
  }
`;

export const FETCH_PROJECT_OWNER = gql`
  subscription getProjectOwner($id: uuid!) {
    projects(where: { id: { _eq: $id } }) {
      owner {
        email
      }
    }
  }
`;

export const GET_DATADOG_CONFIG = gql`
  subscription getDatadogConfig($id: uuid) {
    datadog_config(where: { project_id: { _eq: $id } }) {
      host
      service_name
      tags
      project_id
      logs_last_logged
      key
      datadog_region
      logs_response_code
    }
  }
`;

export const GET_NEWRELIC_CONFIG = gql`
  subscription newrelic_config($id: uuid) {
    newrelic_config(where: { project_id: { _eq: $id } }) {
      attributes
      key
      logs_last_logged
      logs_response_code
      metrics_last_logged
      metrics_response_code
      newrelic_region
      project_id
    }
  }
`;

export const GET_AZURE_CONFIG = gql`
  subscription azuremonitor_config($id: uuid) {
    azuremonitor_config(where: { project_id: { _eq: $id } }) {
      attributes
      azure_region
      activedirectory_client_id
      activedirectory_client_secret
      activedirectory_tenant_id
      azuremonitor_workspace_id
      log_type
      metric_namespace
      shared_key
      resource_id
      logs_last_logged
      logs_response_code
      metrics_last_logged
      metrics_response_code
      project_id
      instrumentation_key
      traces_last_logged
      traces_response_code
      traces_response_message
    }
  }
`;

export const GET_PROMETHEUS_CONFIG = gql`
  subscription prometheus_config($id: uuid) {
    prometheus_config(where: { project_id: { _eq: $id } }) {
      project_id
      labels
      metrics_last_logged
      metrics_response_code
      namespace
    }
  }
`;

export const GET_OPENTELEMETRY_CONFIG = gql`
  subscription opentelemetry_config($id: uuid) {
    opentelemetry_config(where: { project_id: { _eq: $id } }) {
      project_id
      endpoint
      headers
      attributes
      batch_size
      connection_type
      export_traces
      traces_last_exported
      traces_response_code
      traces_response_message
    }
  }
`;

export const GET_HEROKU_CONFIG = gql`
  subscription heroku_integrations($id: uuid!) {
    heroku_integrations(where: { project_id: { _eq: $id } }) {
      var_name
      app_name
    }
  }
`;

export const GET_VPC_PROJECTS = gql`
  ${PROJECT_FRAGMENT}
  ${LABEL_FRAGMENT}
  ${DEDICATED_VPC_FRAGMENT}
  ${VPC_PEERING_FRAGMENT}
  query getVPCProjects($vpcId: uuid) {
    projects(
      where: { tenant: { dedicated_vpc_id: { _eq: $vpcId } } }
      order_by: { created_at: desc }
    ) {
      ...ProjectFields
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
    dedicated_vpc(where: { id: { _eq: $vpcId } }) {
      ...DedicateVPCFields
      vpc_peerings {
        ...VPCPeeringFields
      }
    }
  }
`;

export const GET_JOB_STATUS = gql`
  subscription getJobStatus($jobId: uuid!) {
    jobs_by_pk(id: $jobId) {
      id
      job_type
      project_id
      status
      created_at
      tasks(order_by: { updated_at: asc }) {
        id
        name
        cloud
        region
        task_events(order_by: { updated_at: desc }, limit: 1) {
          event_type
          id
          error
          github_detail
          public_event_data
        }
      }
      github_push_event_jobs {
        github_push_event {
          commit
          commit_message
          commit_timestamp
          github_integration_config {
            organisation
            repository
            branch
            directory
            id
          }
        }
      }
    }
  }
`;

export const FETCH_JOB_ID = gql`
  query fetchJobId($id: uuid!) {
    jobs(
      where: {
        project_id: { _eq: $id }
        job_type: { _in: ["v1_to_v2_upgrade", "region_migration"] }
      }
      order_by: { updated_at: desc }
    ) {
      id
      status
      job_type
    }
  }
`;

export const FETCH_GITHUB_CONFIG = gql`
  subscription fetchGithubConfig($id: uuid!) {
    github_integration_config(
      where: { project_id: { _eq: $id } }
      order_by: { updated_at: desc }
    ) {
      directory
      branch
      organisation
      repository
      app_installation_id
      id
      mode
      email_status
    }
  }
`;

export const FETCH_GITHUB_COMMITS = gql`
  subscription fetchGithubCommits($id: uuid!) {
    github_push_event_job(
      limit: 10
      order_by: { job: { updated_at: desc } }
      where: {
        github_push_event: { github_integration_config_id: { _eq: $id } }
      }
    ) {
      github_push_event {
        commit
        commit_message
        commit_timestamp
        id
      }
      job_id
      job {
        status
        created_at
      }
    }
  }
`;

export const FETCH_LATEST_COMMIT = gql`
  subscription fetchLatestCommit($integrationId: uuid!, $pushMetadata: jsonb!) {
    github_push_event(
      where: {
        _and: [
          { push_metadata: { _contains: $pushMetadata } }
          { github_integration_config_id: { _eq: $integrationId } }
        ]
      }
      order_by: { commit_timestamp: desc }
      limit: 1
    ) {
      id
      github_push_event_jobs(order_by: { job: { created_at: desc } }) {
        job {
          status
        }
      }
      commit
      commit_message
      commit_timestamp
      id
    }
  }
`;

export const GET_PROJECT_MIGRATION_STATUS = gql`
  query getProjectMigrationStatus($projectId: String!) {
    getProjectMigrationStatus(payload: { project_id: $projectId }) {
      datasource
      migration
    }
  }
`;

export const REALTIME_PROJECT_STATUS = gql`
  ${PROJECT_FRAGMENT}
  subscription realtimeProjectStatus($projectId: uuid!) {
    projects_by_pk(id: $projectId) {
      ...ProjectFields
    }
  }
`;

export const GET_GCP_REGIONS = gql`
  query getGCPRegions {
    region_v2(where: { is_active: { _eq: true }, cloud: { _eq: "gcp" } }) {
      name
      region {
        name
        cloud
        nat_ips
      }
    }
  }
`;

export const GET_PROJECT_DB_COUNT = gql`
  query getProjectDbCount($projectId: uuid!) {
    getNumberOfConnectedSources(projectId: $projectId) {
      databases_connected
      project_id
      plan_name
      unsupported
    }
  }
`;

export const GET_PROJECT_DB_USAGE = gql`
  query projectDbUsage($projectId: uuid!, $startDate: date!, $endDate: date!) {
    project_db_usage(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      no_db_usage
      non_pg_usage
      pg_usage
      created_at
      updated_at
    }
  }
`;

export const GET_PROJECT_DATA_PASSTHROUGH = gql`
  query getProjectDataUsagePrometheus(
    $projectId: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    projects(where: { id: { _eq: $projectId } }) {
      project_usage_prometheus(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        date
        usage_updated_at
        usage
        data_usage_components {
          date
          project_id
          hasura_action_request_bytes_total
          hasura_action_response_bytes_total
          hasura_event_trigger_request_bytes_total
          hasura_event_trigger_response_bytes_total
          hasura_http_request_bytes_total
          hasura_http_response_bytes_total
          hasura_scheduled_trigger_request_bytes_total
          hasura_scheduled_trigger_response_bytes_total
          hasura_websocket_messages_received_bytes_total
          hasura_websocket_messages_sent_bytes_total
          usage_updated_at
        }
      }
    }
  }
`;

export const GET_PROJECT_REQUESTS_COUNT = gql`
  query getProjectRequestsCount(
    $projectId: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    project_requests_count(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      hasura_graphql_requests_total
      created_at
      updated_at
    }
  }
`;

export const GET_PROJECTS_V2 = gql`
  ${PROJECTS_LIST_FRAGMENT}
  ${LABEL_FRAGMENT}
  query getProjectsList($offset: Int!, $nameRegex: String, $limit: Int) {
    projects_aggregate(where: { name: { _iregex: $nameRegex } }) {
      aggregate {
        count
      }
    }
    projects(
      where: { name: { _iregex: $nameRegex } }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      ...ProjectsListFields
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
  }
`;

export const GET_CLOUD_REGIONS_BY_PLAN = gql`
  ${REGION_FRAGMENT}
  query getCloudRegionsByPlan {
    region(where: { is_active: { _eq: true } }) {
      ...RegionFields
    }
    cloud_regions_by_plan(where: { is_active: { _eq: true } }) {
      region {
        ...RegionFields
      }
      plan_name
    }
  }
`;

export const GET_PROJECT_LIST_BASE = gql`
  ${PROJECT_LIST_BASE_FRAGMENT}
  ${LABEL_FRAGMENT}
  query getProjectsListBase($offset: Int!, $nameRegex: String, $limit: Int) {
    user_project_map_aggregate(
      where: { project: { name: { _iregex: $nameRegex } } }
    ) {
      aggregate {
        count
      }
    }
    user_project_map(
      where: { project: { name: { _iregex: $nameRegex } } }
      order_by: { project: { created_at: desc } }
      offset: $offset
      limit: $limit
    ) {
      project {
        ...ProjectsListBaseFields
      }
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
  }
`;

export const GET_PROJECT_CARD_INFORMATION = gql`
  query getProjectCardInformation($projectId: uuid!, $tenantId: uuid!) {
    getNumberOfConnectedSources(projectId: $projectId) {
      databases_connected
      project_id
      plan_name
      unsupported
    }
    getTenantEnv(tenantId: $tenantId) {
      hash
      envVars
    }
    project: projects_by_pk(id: $projectId) {
      id
      number_of_requests_this_month
      invoice_status
      invoice_attempt_count
      current_month_data_usage
    }
  }
`;

export const GET_MODEL_BASED_ENTERPRISE_CLOUD_PROJECTS = gql`
  query ModelBasedProjects {
    projects(where: { plan_name: { _eq: "model_based_enterprise_cloud" } }) {
      id
      name
    }
  }
`;
