import styled from 'styled-components';
import {
  color,
  border,
  typography,
  layout,
  space,
  background,
  shadow,
  position,
} from 'styled-system';

import { textEllipses, getCursorType } from '../utils';

export const Box = styled.div`
  width: inherit;
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : '')};

  ${color}
  ${border}
  ${typography}
  ${layout}
  ${space}
  ${shadow}
  ${background}
  ${position}

  ${getCursorType}
  ${textEllipses}
`;
