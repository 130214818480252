import React from 'react';

import { theme } from '../../theme';
import { Text } from '../Typography';
import { StyledDropdown, StyledArrow } from './Dropdown';

export const Dropdown = props => {
  const { type, showArrow, dropdownSize, children, fontSize, width } = props;

  const { dropdown } = theme;

  const backgroundColorValue = dropdown[type]
    ? dropdown[type].bg
    : dropdown.default.bg;

  const dropdownText = dropdown[type] ? dropdown[type].message : '';

  const textColor = dropdown[type]
    ? dropdown[type].text
    : dropdown.default.text;

  const widthValue = dropdownSize === 'small' ? '83px' : '104px';
  const heightValue = dropdownSize === 'small' ? '26px' : '32px';

  return (
    <StyledDropdown
      bg={backgroundColorValue}
      width={width || widthValue}
      height={heightValue}
      {...props}
    >
      <Text
        fontSize={fontSize || '12px'}
        letterSpacing="-0.02em"
        color={textColor}
      >
        {children || dropdownText}
      </Text>
      {type !== 'guest' && showArrow && (
        <StyledArrow color={textColor} showArrow />
      )}
    </StyledDropdown>
  );
};

Dropdown.defaultProps = {
  borderRadius: '80px',
  justifyContent: 'center',
};
