import styled from 'styled-components/macro';
import {
  color,
  border,
  typography,
  layout,
  space,
  shadow,
} from 'styled-system';

export const StyledSpinner = styled.div`
	  position: relative;
	
	  ${color}
	  ${border}
	  ${typography}
	  ${layout}
	  ${space}
	  ${shadow}
	`;
