import React from 'react';
import { useLocation } from 'react-router-dom';

import { authEndpoints } from '../../../utils/constants';
import { validateEmail } from '../../../utils/helpers';
import { NotificationsContext } from '../../Notifications';
import { Input, PrimaryButton } from '../components';
import { useForm } from '../hooks';
import { getRedir } from '../utils';

const formConfig = {
  email: {
    default: '',
    validator: (value: unknown) => {
      if (value === '') {
        return true;
      }

      if (!value) {
        return false;
      }
      if (typeof value !== 'string') {
        return false;
      }

      return validateEmail(value);
    },
  },
} as const;

const SSOSignIn = (): JSX.Element => {
  const [emailFormState, onChangeHandler] = useForm(formConfig);
  const { search } = useLocation();
  const { showErrorNotification } = React.useContext(NotificationsContext);
  const [requestLoadingStatus, updateRequestLoadingStatus] = React.useState(
    false
  );

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const options = {
      method: 'POST',
      body: JSON.stringify({
        email: emailFormState.email.value.toLowerCase().trim(),
      }),
    };

    const getSSOAuthUrl = () => {
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirect_url');

      if (
        redirectUrl &&
        (redirectUrl.includes('console.hasura.io') ||
          redirectUrl.includes('console.arusah.com'))
      ) {
        const platform = redirectUrl.includes('promptql')
          ? 'promptql_v3_console'
          : 'hasura_v3_console';
        return `${authEndpoints.samlSSO}?redir=${getRedir(
          search
        )}?platform=${platform}`;
      }

      return `${authEndpoints.samlSSO}?redir=${getRedir(search)}`;
    };

    updateRequestLoadingStatus(true);

    return fetch(getSSOAuthUrl(), options)
      .then(r => {
        if (r.status >= 500) {
          showErrorNotification('unexpected error has occurred');
        }
        if (r.status >= 400) {
          showErrorNotification('Email not registered with SSO');
        } else {
          r.json().then(resp => {
            window.location.replace(resp.auth_url);
          });
        }
      })
      .catch(err => {
        updateRequestLoadingStatus(false);
        console.error('Error performing SSO', err.message);
        showErrorNotification(
          'Unexpected failure with SSO. Please get in touch.'
        );
      });
  };

  const isInputFilledAndValid = React.useMemo(() => {
    return Boolean(emailFormState.email.value && emailFormState.email.isValid);
  }, [emailFormState.email]);

  return (
    <div className="flex flex-col">
      <form onSubmit={onFormSubmit} className="contents">
        <Input
          placeholder="Email"
          type="email"
          value={emailFormState.email.value}
          onChange={onChangeHandler('email')}
          isErrored={!emailFormState.email.isValid}
          errorText="Please enter a valid email address"
        />
        <PrimaryButton
          disabled={!isInputFilledAndValid}
          type="submit"
          variant="primary"
          style={{ marginTop: '16px' }}
          loading={requestLoadingStatus}
        />
      </form>
    </div>
  );
};

export default SSOSignIn;
