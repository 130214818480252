import * as React from 'react';

import { Image } from '../../../UIKit';
import hasuraLogoBlack from '../../../images/hasura_black_logo.svg';
import hasuraLogoWhite from '../../../images/hasura_white_logo.svg';
import hasuraEeDark from '../../../images/hasura_ee_dark.svg';
import hasuraEeFullBlack from '../../../images/hasura_ee_full_black.svg';
import hasuraEeLight from '../../../images/hasura_ee_light.svg';
import hasuraCloudGray from '../../../images/hasura_cloud_gray_logo.svg';
import { EE_MODE } from '../../../../utils/constants';
import { useContext } from 'react';
import { DashboardContext } from '../../store';

type Props = {
  color?: 'white' | 'black' | 'full-black' | 'gray';
  width?: string;
};

const HasuraLogo: React.FC<Props> = ({ color = 'black', width = '115px' }) => {
  const { isEnterpriseUser } = useContext(DashboardContext);

  if (EE_MODE || isEnterpriseUser) {
    const selectBlackLogo =
      color === 'black' ? hasuraEeDark : hasuraEeFullBlack;

    return (
      <Image
        src={color === 'white' ? hasuraEeLight : selectBlackLogo}
        alt="hasura-ee"
        minWidth="115px"
        width={width}
      />
    );
  }
  return (
    <Image
      src={
        color === 'white'
          ? hasuraLogoWhite
          : color === 'black'
          ? hasuraLogoBlack
          : hasuraCloudGray
      }
      alt="hasura-cloud"
      minWidth="115px"
      width={width}
    />
  );
};

export default HasuraLogo;
