import { gql } from '@apollo/client';

export const GET_CARD_DETAILS = gql`
  subscription getCardDetails {
    payment_method(
      where: { status: { _eq: "active" } }
      order_by: { created_at: desc }
    ) {
      id
      is_default
      name
      exp_year
      exp_month
      last4
      brand
      status
    }
  }
`;

export const GET_DEFAULT_CARD_DETAILS = gql`
  subscription getDefaultCardDetails {
    payment_method(
      where: { status: { _eq: "active" }, is_default: { _eq: true } }
      order_by: { created_at: desc }
    ) {
      id
      is_default
      name
      exp_year
      exp_month
      last4
      brand
      status
    }
  }
`;

export const GET_PROJECT_USAGE_AND_REPORT = gql`
  subscription getProjectUsageAndReport(
    $id: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    projects(where: { id: { _eq: $id } }) {
      project_usages(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        usage
        date
      }
      usage_reports(
        where: { report_date: { _gte: $startDate, _lte: $endDate } }
        order_by: { report_date: asc }
      ) {
        key
        report_date
      }
    }
  }
`;

export const GET_PROJECT_USAGE = gql`
  subscription getProjectUsage($id: uuid!, $startDate: date!, $endDate: date!) {
    projects(where: { id: { _eq: $id } }) {
      project_usages(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        usage
        date
      }
    }
  }
`;

export const GET_REPORT_URL = gql`
  query getReportURL($key: String!) {
    getReportURL(key: $key) {
      url
    }
  }
`;

export const GET_BILLING_INVOICE = gql`
  subscription getLatestInvoice {
    invoice(
      order_by: [{ year: desc }, { month: desc }]
      where: { status: { _in: ["paid", "open"] } }
    ) {
      stripe_invoice_id
      month
      year
      invoice_url
      receipt_url
      status
      due_amount
      collection_method
      items {
        amount
      }
    }
  }
`;

export const GET_DEFAULT_CARD_DETAILS_STATIC = gql`
  query fetchCardDetails {
    payment_method(
      where: { is_default: { _eq: true }, status: { _eq: "active" } }
    ) {
      id
      is_default
      name
      exp_year
      exp_month
      last4
      brand
      status
    }
  }
`;

export const GET_USED_COUPONS = gql`
  subscription getUsedCoupons {
    user_coupon {
      amount_off
      is_applied
      promotion_code
      created_at
      percentage_off
      coupon {
        duration
        duration_in_months
      }
    }
  }
`;

export const GET_USED_CREDIT = gql`
  subscription getUsedCredit {
    customer_usage {
      amount
      code
      created_at
      coupon {
        duration_in_months
        duration
      }
    }
  }
`;

export const GET_INVOICED_COUPONS = gql`
  subscription getInvoicedCoupons {
    invoice_coupon_discount {
      coupon_code
      created_at
      invoice {
        month
        year
      }
    }
  }
`;

export const GET_PENDING_INVOICE_STATUS = gql`
  subscription getPendingInvoiceStatus {
    invoice(
      order_by: [{ year: desc }, { month: desc }]
      where: { status: { _eq: "open" } }
      limit: 1
    ) {
      invoice_url
      stripe_invoice_id
      attempt_count
      collection_method
    }
  }
`;

export const GET_INVOICE_AND_RECEIPT_URLS = gql`
  query getInvoiceAndReceiptURLs($invoice_id: String!) {
    getInvoiceAndReceiptURLs(invoice_id: $invoice_id) {
      invoice_url
      receipt_url
    }
  }
`;

export const GET_PROJECT_USAGE_PROMETHEUS = gql`
  subscription getProjectUsagePrometheus(
    $id: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    projects(where: { id: { _eq: $id } }) {
      project_usage_prometheus(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        usage
        date
      }
    }
  }
`;

export const GET_PROJECT_MODELS = gql`
  query getProjectModels(
    $projectIds: [String!]!
    $startDate: Timestamp
    $endDate: Timestamp
  ) {
    model_based_pricing_model_snapshot_statistics(
      where: {
        project_id: { _in: $projectIds }
        timestamp: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      project_id
      timestamp
      tables
      collections
      logical_models
    }
  }
`;
