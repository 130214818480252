import React from 'react';

import { StyledNotification } from './Notification';
import { Icon } from '../../icons';
import { Flex } from '../Flex';
import { Text } from '../Typography';

export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_TYPE_WARNING = 'warning';
export const NOTIFICATION_TYPE_ALERT = 'alert';
export const NOTIFICATION_TYPE_DISMISS = 'dismiss';

export const getNotificationProperties = type => {
  switch (type) {
    case NOTIFICATION_TYPE_WARNING: {
      return {
        text: 'grey.original',
        iconColor: 'orange.warningdark',
        background: 'orange.light',
        icon: 'warn',
      };
    }
    case NOTIFICATION_TYPE_ERROR: {
      return {
        text: 'white',
        iconColor: 'red.errordark',
        background: 'red.error',
        icon: 'warn',
      };
    }
    case NOTIFICATION_TYPE_ALERT: {
      return {
        text: 'white',
        iconColor: 'blue.alertdark',
        background: 'blue.alert',
        icon: 'bell',
      };
    }
    case NOTIFICATION_TYPE_SUCCESS: {
      return {
        text: 'white',
        iconColor: 'blue.successdark',
        background: 'blue.success',
        icon: 'check',
      };
    }
    case NOTIFICATION_TYPE_DISMISS: {
      return {
        text: 'white',
        iconColor: 'blue.successdark',
        background: 'blue.success',
        icon: 'check',
      };
    }
    default: {
      return {
        text: 'grey.original',
        iconColor: 'orange.primary',
        background: 'orange.light',
        icon: 'warn',
      };
    }
  }
};

const getPlacementProps = placement => {
  return {
    pl: placement === 'center' ? '0%' : '20%',
  };
};

export const Base = ({ type, message, dismiss, placement }) => {
  const {
    text: textColor,
    background: backgroundColor,
    iconColor,
    icon: iconName,
  } = getNotificationProperties(type);

  return (
    <Flex
      width="100%"
      justifyContent="center"
      {...getPlacementProps(placement)}
      data-testid="default-notification-id"
    >
      <StyledNotification
        backgroundColor={backgroundColor}
        p="0 15px"
        boxShadow={1}
        minWidth="424px"
        width="auto"
        maxWidth="800px"
        zIndex="notification"
      >
        <Flex
          flex="0.1"
          backgroundColor={iconColor}
          justifyContent="center"
          minWidth="31px"
          maxWidth="31px"
          height="31px"
          borderRadius="50%"
          color="white"
        >
          <Icon type={iconName} color="white" />
        </Flex>
        <Text
          flex="0.8"
          color={textColor}
          justifyContent="center"
          py="10px"
          pl="25px"
          pr="15px"
        >
          {message}
        </Text>
        <Flex
          role="button"
          onClick={dismiss}
          color={textColor}
          justifyContent="center"
          minWidth="33px"
          borderRadius="50%"
          size="33px"
          pointer
          pl="4px"
          id="notification-close-btn"
        >
          <Icon type="close" color="white" />
        </Flex>
      </StyledNotification>
    </Flex>
  );
};

export { Base as Notification };
