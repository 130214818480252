import React from 'react';

import { authEndpoints } from './constants';
import requestAuthServer from './requestAuthServer';
import { paths } from './routeUtils';
import { clearPersistedHerokuSession } from './localStorage';
import { parseQueryString, parseOauthFlowError, isLoggedIn } from './helpers';
import { resetPosthog } from './posthog';
import { segmentEvents, segmentTrack } from '../utils/segment';
import { sentryResetUser } from '../utils/sentry';

const acceptLoginRequest = url => {
  const options = {
    credentials: 'include',
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({}),
  };
  return fetch(url, options)
    .then(resp => {
      if (resp.status < 400) {
        // Mark as success
        return resp.json();
      }
      throw new Error('Error accepting your request');
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

const verifyLoginRequest = (parsedQueryString, navigate, callback) => {
  acceptLoginRequest(
    authEndpoints.acceptLogin(parsedQueryString.login_challenge)
  )
    .then(resp => {
      if (resp.status !== 'failure') {
        if ('location' in resp) {
          window.location.href = resp.location;
        }
      } else {
        throw new Error(parseOauthFlowError(resp));
      }
    })
    .catch(err => {
      navigate({
        pathname: paths.oauthError(),
        search: `?err=${err}`,
      });
      callback();
    });
};

const authRedirect = (navigate, location, url, callback) => {
  const { search } = location;
  const parsedQueryString = parseQueryString(search);
  // const currentHostname = window.location.hostname;

  if ('login_challenge' in parsedQueryString) {
    verifyLoginRequest(parsedQueryString, navigate, callback);
  } else if (url || 'redirect_url' in parsedQueryString) {
    /* Constructs a new url with url if it is absolute and otherwise uses the second parameter as BASE */
    const redirectTo = url || parsedQueryString.redirect_url;
    const absUrlChecker = /^https?:\/\//i;
    if (absUrlChecker.test(redirectTo)) {
      const redirectToURL = new URL(redirectTo);
      // TODO: Redirect to '/' for now
      window.location.href = redirectTo;
      // if (redirectToURL.hostname === currentHostname) {
      //   // Redirect to the same domain
      //   window.location.href = redirectTo;
      // } else {
      //   // Redirect to '/' for security reasons
      //   // We don't want to redirect authenticated users to external domains
      //   navigate('/');
      // }
      return;
    }
    navigate(redirectTo);
    callback();
  }
};

export const logout = () => {
  const options = {
    credentials: 'include',
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
    },
  };
  return fetch(authEndpoints.logout, options).then(resp => {
    return resp.json();
  });
};

export const cleanupBrowserState = client => {
  clearPersistedHerokuSession();
  if (client) {
    client.clearStore();
  }
  resetPosthog();
  window.localStorage.clear();
  window.sessionStorage.clear();
};

export const useLogout = () => {
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const [error, setError] = React.useState('');
  const handleLogout = client => {
    if (isLoggingOut) return;
    setIsLoggingOut(true);
    logout()
      .then(resp => {
        if ('status' in resp && resp.status === 'failure') {
          throw new Error(resp.error);
        }
        segmentTrack(segmentEvents.logout, {
          label: 'Logged Out',
        });
        sentryResetUser();
        cleanupBrowserState(client);
        window.location.href = '/';
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
        setError(`Unexpected error. Failed to logout.`);
      })
      .finally(() => {
        setIsLoggingOut(false);
      });
  };

  return {
    error,
    isLoggingOut,
    logout: handleLogout,
  };
};

const checkUserLogin = () => {
  const options = {
    credentials: 'include',
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  };

  return requestAuthServer(authEndpoints.userInfo, options);
};

export const isUserLoggedIn = () => {
  return checkUserLogin()
    .then(r => {
      return isLoggedIn(r);
    })
    .catch(() => false);
};

export const authMethods = {
  github(redirect) {
    window.open(`${authEndpoints.githubOAuthUrl}?redir=${redirect}`, '_parent');
  },
  google(redirect) {
    window.open(`${authEndpoints.googleOAuthUrl}?redir=${redirect}`, '_parent');
  },
};

export const getSessionRole = async () => {
  try {
    const res = await fetch(authEndpoints.userInfo, {
      credentials: 'include',
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    });

    const userInfo = await res.json();
    return userInfo['X-Hasura-Role'];
  } catch {
    return '';
  }
};

export { authRedirect };
