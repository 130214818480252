import React from 'react';
import { StyledSVG } from '../Icon';

export const UpdateIcon = props => {
  const { color } = props;

  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 24 24"
      fill={color}
      {...props}
    >
      <path className="st1" d="M16,18v2H8v-2H16z M11,8v8h2V8h3l-4-4L8,8H11z" />
    </StyledSVG>
  );
};
